import { ref, reactive } from 'vue'
import * as math from 'mathjs'

export const PI = Math.PI;

export function cos(val) {
  return Math.cos(val)
}

export function sin(val) {
  return Math.sin(val)
}

export function toRads(degs) {
  return 2*PI*degs/360.0;
}

export function toDegs(rads) {
  return 360.0*rads/(2*PI);
}

export function arcsin(val) {
  return Math.asin(val)
}

export function arccos(val) {
  return Math.acos(val)
}

// Result in radians, [-pi/2, pi/2]
export function arctan(val) {
  return Math.atan(val)
}

export function sqrt(val) {
  return Math.sqrt(val);
}

export function ln(val) {
  return Math.log(val);
}

export function log(val) {
  return Math.log10(val);
}

export function exp(val) {
  return Math.exp(val);
}

export class MatrixUtils {
  static getRow(m, rowIndex) {
    return math.row(m, rowIndex).toArray()[0]
  }

  static setRow(m, rowIndex, newRowArr) {
    for (let i = 0; i < newRowArr.length; i++) {
      m.set([rowIndex, i], newRowArr[i]);
    }
  }

  static setCol(m, colIndex, newColArr) {
    for (let i = 0; i < newColArr.length; i++) {
      m.set([i, colIndex], newColArr[i]);
    }
  }
};


export function makeVector(n) {
  return new Array(n).fill(0)
}

export function makeMonthVector(monthIndex) {
  let arr = new Array(12).fill(0)
  arr[monthIndex] = 1
  return arr
}

export function makeHourVector(hourIndex) {
  let arr = new Array(24).fill(0)
  arr[hourIndex] = 1
  return arr
}
