import { MaterialDataTable } from './MaterialDataTable.js';
import { WallTypeOption, RoofTypeOption } from '../Components.js';
import { colStrToIndex } from './CsvHelper.js';
import { makeVector } from '../Math.js';
import { lookupData, makeEnum, } from '../Base.js';
import { logIf, prettyJson, } from '../SharedUtils.js';
import { YesNo } from '../Components.js';
import { SpaceType, BuildingMass, SpacePercentGlass, SpaceHasCarpet } from '../Space.js'
import * as math from 'mathjs'

export { SpaceType, BuildingMass, SpacePercentGlass, SpaceHasCarpet } from '../Space.js'

let debugging = false

function findClosestItem(items, value, lookupFunc) {
  let closestItem = null
  let closestDist = Number.MAX_VALUE
  for (const item of items) {
    let dist = math.abs(lookupFunc(item) - value)
    if (closestItem === null || dist < closestDist) {
      closestItem = item
      closestDist = dist
    }
  }
  if (!closestItem) {
    throw new Error(`Could not find closest item for value: ${value}`)
  }
  return closestItem
}

/**
 * TimeSeriesDataTable
 * 
 * Helper class for looking up CTS values for wall and roof types from the CTS data CSV.
 */
export class TimeSeriesDataTable extends MaterialDataTable {
  constructor(tableDesc) {
    super(tableDesc)
    this._parseCTSValues()
    this._parseRTSValues()
  }

  _parseCTSValues() {
    /*
    WallTypeOptions:
    Curtain wall
    Stud wall
    EIFS
    Brick wall
    Concrete block wall
    Precast / Cast-in-place block wall
    Unknown
    */
    this.wallCTSValues = {
      [WallTypeOption.CurtainWall]: {
        colName: 'Curtain Wall',
        firstCol: 'B',
        lastCol: 'G',
      },
      [WallTypeOption.StudWall]: {
        colName: 'Stud Wall',
        firstCol: 'H',
        lastCol: 'O',
      },
      [WallTypeOption.EIFS]: {
        colName: 'EIFS',
        firstCol: 'P',
        lastCol: 'U',
      },
      [WallTypeOption.BrickWall]: {
        colName: 'Brick Wall',
        firstCol: 'V',
        lastCol: 'AO',
      },
      [WallTypeOption.ConcreteBlockWall]: {
        colName: 'Concrete Block Wall',
        firstCol: 'AP',
        lastCol: 'AX',
      },
      [WallTypeOption.PrecastCastInPlaceBlockWall]: {
        colName: 'Precast Block Wall',
        firstCol: 'AY',
        lastCol: 'BO',
      },
    }
    /*
    Roof type options:
    Sloped frame roof
    Wood deck roof
    Metal deck roof
    Concrete roof
    Unknown
    */
    this.roofCTSValues = {
      [RoofTypeOption.SlopedFrameRoof]: {
        colName: 'Sloped Frame Roof',
        firstCol: 'B',
        lastCol: 'M',
      },
      [RoofTypeOption.WoodDeckRoof]: {
        colName: 'Wood Deck Roof',
        firstCol: 'N',
        lastCol: 'Q',
      },
      [RoofTypeOption.MetalDeckRoof]: {
        colName: 'Metal Deck Roof',
        firstCol: 'R',
        lastCol: 'Z',
      },
      [RoofTypeOption.ConcreteRoof]: {
        colName: 'Concrete Roof',
        firstCol: 'AA',
        lastCol: 'AL',
      },
    }
    for (const wallType in this.wallCTSValues) {
      let entry = this.wallCTSValues[wallType]
      entry.values = this._parseCTSValuesForColName(entry.colName, entry.firstCol, entry.lastCol, 7, 42)
    }
    for (const roofType in this.roofCTSValues) {
      let entry = this.roofCTSValues[roofType]
      entry.values = this._parseCTSValuesForColName(entry.colName, entry.firstCol, entry.lastCol, 51, 90)
    }
  }

  _parseRTSValues() {
    let nonSolarRTSDict = {
      [SpaceType.Exterior]: {
        [BuildingMass.Light]: {
          [SpaceHasCarpet.Yes]: {
            [SpacePercentGlass.p10]: {row: 100, col: 'C'},
            [SpacePercentGlass.p50]: {row: 100, col: 'D'},
            [SpacePercentGlass.p90]: {row: 100, col: 'E'},
          },
          [SpaceHasCarpet.No]: {
            [SpacePercentGlass.p10]: {row: 100, col: 'F'},
            [SpacePercentGlass.p50]: {row: 100, col: 'G'},
            [SpacePercentGlass.p90]: {row: 100, col: 'H'},
          }
        },
        [BuildingMass.Medium]: {
          [SpaceHasCarpet.Yes]: {
            [SpacePercentGlass.p10]: {row: 100, col: 'J'},
            [SpacePercentGlass.p50]: {row: 100, col: 'K'},
            [SpacePercentGlass.p90]: {row: 100, col: 'L'},
          },
          [SpaceHasCarpet.No]: {
            [SpacePercentGlass.p10]: {row: 100, col: 'M'},
            [SpacePercentGlass.p50]: {row: 100, col: 'N'},
            [SpacePercentGlass.p90]: {row: 100, col: 'O'},
          }
        },
        [BuildingMass.Heavy]: {
          [SpaceHasCarpet.Yes]: {
            [SpacePercentGlass.p10]: {row: 100, col: 'Q'},
            [SpacePercentGlass.p50]: {row: 100, col: 'R'},
            [SpacePercentGlass.p90]: {row: 100, col: 'S'},
          },
          [SpaceHasCarpet.No]: {
            [SpacePercentGlass.p10]: {row: 100, col: 'T'},
            [SpacePercentGlass.p50]: {row: 100, col: 'U'},
            [SpacePercentGlass.p90]: {row: 100, col: 'V'},
          }
        }
      },
      [SpaceType.Interior]: {
        [BuildingMass.Light]: {
          [SpaceHasCarpet.Yes]: {
            _ELSE: {row: 100, col: 'X'},
          },
          [SpaceHasCarpet.No]: {
            _ELSE: {row: 100, col: 'Y'},
          }
        },
        [BuildingMass.Medium]: {
          [SpaceHasCarpet.Yes]: {
            _ELSE: {row: 100, col: 'Z'},
          },
          [SpaceHasCarpet.No]: {
            _ELSE: {row: 100, col: 'AA'},
          }
        },
        [BuildingMass.Heavy]: {
          [SpaceHasCarpet.Yes]: {
            _ELSE: {row: 100, col: 'AB'},
          },
          [SpaceHasCarpet.No]: {
            _ELSE: {row: 100, col: 'AC'},
          }
        }
      }
    }
    this.nonSolarRTSValues = this._parseRTSDict(nonSolarRTSDict)
    logIf(debugging, "Non-Solar RTS values:", prettyJson(this.nonSolarRTSValues))

    let solarRTSDict = {
      [SpaceType.Exterior]: {
        [BuildingMass.Light]: {
          [SpaceHasCarpet.Yes]: {
            [SpacePercentGlass.p10]: {row: 131, col: 'C'},
            [SpacePercentGlass.p50]: {row: 131, col: 'D'},
            [SpacePercentGlass.p90]: {row: 131, col: 'E'},
          },
          [SpaceHasCarpet.No]: {
            [SpacePercentGlass.p10]: {row: 131, col: 'F'},
            [SpacePercentGlass.p50]: {row: 131, col: 'G'},
            [SpacePercentGlass.p90]: {row: 131, col: 'H'},
          }
        },
        [BuildingMass.Medium]: {
          [SpaceHasCarpet.Yes]: {
            [SpacePercentGlass.p10]: {row: 131, col: 'J'},
            [SpacePercentGlass.p50]: {row: 131, col: 'K'},
            [SpacePercentGlass.p90]: {row: 131, col: 'L'},
          },
          [SpaceHasCarpet.No]: {
            [SpacePercentGlass.p10]: {row: 131, col: 'M'},
            [SpacePercentGlass.p50]: {row: 131, col: 'N'},
            [SpacePercentGlass.p90]: {row: 131, col: 'O'},
          }
        },
        [BuildingMass.Heavy]: {
          [SpaceHasCarpet.Yes]: {
            [SpacePercentGlass.p10]: {row: 131, col: 'Q'},
            [SpacePercentGlass.p50]: {row: 131, col: 'R'},
            [SpacePercentGlass.p90]: {row: 131, col: 'S'},
          },
          [SpaceHasCarpet.No]: {
            [SpacePercentGlass.p10]: {row: 131, col: 'T'},
            [SpacePercentGlass.p50]: {row: 131, col: 'U'},
            [SpacePercentGlass.p90]: {row: 131, col: 'V'},
          }
        }
      },
    }
    this.solarRTSValues = this._parseRTSDict(solarRTSDict)
    logIf(debugging, "Solar RTS values:", prettyJson(this.solarRTSValues))
  }

  _modifyRTSDict(rtsDict) {
    for (const key in rtsDict) {
      let value = rtsDict[key]
      if ('row' in value) {
        value.rtsVec = makeVector(24)
        for (let i = 0; i < 24; ++i) {
          value.rtsVec[i] = this.csvHelper.lookupValue((value.row - 1) + i, value.col, Number) / 100.0
        }
      } else {
        this._modifyRTSDict(value)
      }
    }
  }

  _parseRTSDict(rtsDict) {
    this._modifyRTSDict(rtsDict)
    return rtsDict
  }

  _parseCTSValuesForColName(colName, firstCol, lastCol, ctsRowStart, wallWeightRow) {
    //logIf(debugging, "Parsing CTS values for", colName)
    let values = []
    let firstColIndex = colStrToIndex(firstCol)
    let lastColIndex = colStrToIndex(lastCol)
    for (let colIndex = firstColIndex; colIndex <= lastColIndex; ++colIndex) {
      let colItem = {
        ctsVec: makeVector(24),
        wallWeight: 0.0,
      }
      for (let hr = 0; hr < 24; ++hr) {
        colItem.ctsVec[hr] = this.csvHelper.lookupValue((ctsRowStart - 1) + hr, colIndex, Number) / 100.0
      }
      colItem.wallWeight = this.csvHelper.lookupValue((wallWeightRow - 1), colIndex, Number)
      values.push(colItem)
    }
    //logIf(debugging, "Values: ", values)
    return values
  }

  _lookupCTSVecForUnknownWallType(wallWeight, isWall) {
    // When the wall type is unknown, we average the looked up CTS values for all wall types
    //logIf(debugging, "Looking up CTS values for unknown wall type, with weight: ", wallWeight)
    let averagedVec = makeVector(24)
    let ctsDict = isWall ? this.wallCTSValues : this.roofCTSValues;
    for (const wallType in ctsDict) {
      let ctsEntry = ctsDict[wallType]
      let entry = findClosestItem(ctsEntry.values, wallWeight, (item) => item.wallWeight)
      //logIf(debugging, "Found closest item for wall type ", wallType, entry.ctsVec)
      averagedVec = math.add(averagedVec, entry.ctsVec)
    }
    let res = math.divide(averagedVec, Object.keys(ctsDict).length)
    //logIf(debugging, "Averaged CTS values: ", res)
    return res
  }

  _lookupCTSVec(ctsData, wallWeight) {
    let entry = findClosestItem(ctsData.values, wallWeight, (item) => item.wallWeight)
    return entry.ctsVec
  }

  getCTSValuesForWallType(wallType) {
    if (wallType.type.value === WallTypeOption.Unknown) {
      return this._lookupCTSVecForUnknownWallType(wallType.getWallWeight(), true)
    } else {
      let ctsEntry = lookupData(this.wallCTSValues, [wallType.type.value])
      return this._lookupCTSVec(ctsEntry, wallType.getWallWeight())
    }
  }

  getCTSValuesForRoofType(roofType) {
    if (roofType.type.value === RoofTypeOption.Unknown) {
      return this._lookupCTSVecForUnknownWallType(roofType.getWallWeight(), false)
    } else {
      let ctsEntry = lookupData(this.roofCTSValues, [roofType.type.value])
      return this._lookupCTSVec(ctsEntry, roofType.getWallWeight())
    }
  }

  /**
   * Args requires:
   * - spaceType: SpaceType
   * - buildingMass: BuildingMass
   * - hasCarpet: SpaceHasCarpet
   * - percentGlass: SpacePercentGlass
   */
  getNonSolarRTSValues(args) {
    return lookupData(this.nonSolarRTSValues,
      [args.spaceType, args.buildingMass, args.hasCarpet, args.percentGlass]).rtsVec
  }

  /**
   * Args requires:
   * - spaceType: SpaceType
   * - buildingMass: BuildingMass
   * - hasCarpet: SpaceHasCarpet
   * - percentGlass: SpacePercentGlass
   */
  getSolarRTSValues(args) {
    if (args.spaceType == SpaceType.Interior) {
      return makeVector(24)
    }
    return lookupData(this.solarRTSValues,
      [args.spaceType, args.buildingMass, args.hasCarpet, args.percentGlass]).rtsVec
  }

  static getLoader(tableDesc) {
    return () => new TimeSeriesDataTable(tableDesc)
  }
};
