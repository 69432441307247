import {
  makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { valOr } from './SharedUtils.js'
import * as ser from './SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from './Field.js'
import { Season, DoorColor, Wall, Roof, Partition, HouseFloor,
  AutomaticOrManual, YesNo, makeNoYesField, makeYesNoField,
  ShadesOrientation, } from './Components.js'
import { FieldWithVariableUnits } from './FieldUtils.js'
import { Units } from './Units.js'

import { gApp, DebugOn } from './State.js'

import { SpaceTypes, SpaceTypeCategory, GetSpaceTypesForCategory, DummySpaceData } from './SpaceTypes.js'
import { VentilationEffectivenessData, } from './VentilationEffectivenessData.js'
import { InfiltrationHours } from './Space.js'
import { SpaceInternals } from './SpaceInternals.js'
import { DataTable } from './DataTable.js'
import { CalcContext } from './CalcContext.js'
import { CalcPsychrometrics, PsyCalcMethod } from './Psychrometrics.js'
import * as solar from './SolarCalculations.js'

import { MatrixUtils, makeVector, makeMonthVector, makeHourVector, } from './Math.js'
import * as math from 'mathjs'
import { IACCalculator } from './IACCalculator.js'
import { pipeToNodeWritable } from 'vue/server-renderer'

let DoorAlphaMap = {
  [DoorColor.Light]: 0.30,
  [DoorColor.Medium]: 0.60,
  [DoorColor.Dark]: 0.90,
};

function getReversed(arr) {
  let copy = math.clone(arr)
  copy.reverse()
  return copy
}

// Make a true mod (unlike JS % operator, which handles -ves weird
// because it is a remainder operator, not a mod operator)
function mod(n, m) {
  return ((n % m) + m) % m;
}

export function reverseAboutStart(arr) {
  let copy = math.clone(arr)
  for (let i = 0; i < arr.length; ++i) {
    copy[i] = arr[mod(-i, arr.length)]
  }
  return copy
}

export class TimeSeriesUtils {
  static transformVec(ctx, vecToTransform, timeSeriesVec) {
    let numHrs = vecToTransform.length
    let reversedTimeSeriesVec = reverseAboutStart(timeSeriesVec)
    let res = makeVector(numHrs)
    for (let hr = 0; hr < numHrs; ++hr) {
      let adjustmentVec = makeVector(numHrs)
      for (let j = 0; j < numHrs; ++j) {
        adjustmentVec[(hr + j) % numHrs] = reversedTimeSeriesVec[j]
      }
      res[hr] = math.dot(vecToTransform, adjustmentVec)
    }
    return res
  }

  static transformMatrix(ctx, matrixToTransform, timeSeriesVec) {
    ctx.startSection("timeSeriesTransformMatrix")
    let numMonths = matrixToTransform.size()[0]
    let numHrs = matrixToTransform.size()[1]
    ctx.res = math.zeros(numMonths, numHrs)
    for (let i = 0; i < numMonths; i++) {
      let row = MatrixUtils.getRow(matrixToTransform, i)
      let adjustedVec = this.transformVec(ctx, row, timeSeriesVec)
      MatrixUtils.setRow(ctx.res, i, adjustedVec)
    }
    let res = ctx.res
    ctx.logLoadMatrix('res', res)
    ctx.endSection()
    return res
  }
}

export class SpaceCoolingCalculator {
  init(space, ctx) {
    this.space = space
    this.ctx = ctx
  }

  async calcInfiltrationLatentLoads() {
    let ctx = this.ctx
    ctx.setProgressText("Calculating infiltration latent loads")
    console.log("Calc infiltration latent loads")
    ctx.startSection("Infiltration Latent Loads")
    ctx.I_lat = math.zeros(12, 24)
    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Summer);
    // TODO - what is the equation to adjust for altitude?
    ctx.C_l = 4840
    let onlyDuringOccupiedHours = this.space.infiltrationHours.value == InfiltrationHours.OccupiedHours;
    ctx.log(`Only during occupied hours: ${onlyDuringOccupiedHours}`)
    ctx.occupancySchedule = this.space.internals.people.getSchedule().getData();
    for (let month = 0; month < 12; month++) {
      ctx.startSection(`Month ${month}`)
      for (let hour = 0; hour < 24; hour++) {
        console.log(`Month ${month}, Hour ${hour}`)
        ctx.setProgressText(`Infiltration latent loads - Month ${month}, Hour ${hour}`)
        await ctx.briefWait()
        ctx.startSection(`Hour ${hour}`)
        if (onlyDuringOccupiedHours && ctx.occupancySchedule[hour] == 0) {
          ctx.log("Unoccupied hour")
          ctx.I_lat_item = 0;
        } else {
          ctx.W_in = ctx.call(CalcPsychrometrics, ctx.t_i, ctx.altitude,
            PsyCalcMethod.CalcWithRelativeHumidity, {
              RH: ctx.summerIndoorRH
            }
          ).W;
          // TODO - not yet impl
          ctx.log("W_out not yet implemented")
          ctx.W_out = -1
          ctx.I_lat_item = ctx.eval('Q_inf*C_l*(W_out - W_in)', {}, 'I_lat_item')
        }
        ctx.I_lat.set([month, hour], ctx.I_lat_item)
        ctx.endSection()
      }
      ctx.endSection()
    }
    ctx.logLoadMatrix('I_lat', ctx.I_lat)
    let res = ctx.I_lat
    ctx.endSection()
    return res
  }

  async calcApplianceLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating appliance latent loads")
    let applianceLoads = this.space.internals.appliances;
    ctx.startSection(`Appliance Latent Loads`)
    ctx.A_lat_row = new Array(24).fill(0);
    for (let i = 0; i < applianceLoads.appliances.length; i++) {
      ctx.log(`Appliance ${i}:`)
      let app = applianceLoads.appliances[i];
      ctx.quantity = app.getQuantity();
      ctx.A_lat_app = app.getLatentLoad(ctx);
      ctx.appSched = app.getSchedule().getData();
      for (let hr = 0; hr < 24; hr++) {
        let schedFactor = ctx.appSched[hr] > 0 ? 1 : 0;
        ctx.A_lat_row[hr] += ctx.quantity * ctx.A_lat_app * schedFactor * app.getDiversityFactor();
      }
    }
    // Same for each month:
    ctx.A_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.A_lat, i, ctx.A_lat_row)
    }
    ctx.logLoadMatrix('A_lat', ctx.A_lat)
    let res = ctx.A_lat
    ctx.endSection()
    return res;
  }

  async calcPeopleLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating people latent loads")
    ctx.startSection(`People Latent Loads`)
    let people = this.space.internals.people;
    ctx.sched = people.getSchedule().getData();
    ctx.M = people.getNumOccupants()
    ctx.activityLevel = people.activityLevel.value
    let activityLevelData = people.getActivityLevelData(ctx)
    ctx.L = activityLevelData.Latent_Heat
    ctx.P_lat_mo0 = ctx.eval(`sched * M * L`, {}, 'P_lat')
    // Same for each month:
    ctx.P_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.P_lat, i, ctx.P_lat_mo0)
    }
    ctx.logLoadMatrix('P_lat', ctx.P_lat)
    let res = ctx.P_lat
    ctx.endSection()
    return res
  }

  async calcMiscLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating misc latent loads")
    ctx.startSection(`Misc Latent Loads`)
    ctx.M_lat_row = new Array(24).fill(0);

    // Note: there is only one misc load entry for now
    let miscLoads = [this.space.internals.miscLoads];
    for (let i = 0; i < miscLoads.length; i++) {
      let misc = miscLoads[i];
      ctx.log(`Misc ${i}:`)
      ctx.M_entry = misc.getLatentLoad(ctx);
      ctx.entrySched = misc.getSchedule().getData();
      for (let hr = 0; hr < 24; hr++) {
        ctx.M_lat_row[hr] += ctx.M_entry * ctx.entrySched[hr] * misc;
      }
    }

    // Same for each month
    ctx.M_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.M_lat, i, ctx.M_lat_row)
    }

    ctx.logLoadMatrix('M_lat', ctx.M_lat)
    let res = ctx.M_lat
    ctx.endSection()
    return res
  }

  /**
   * 
   * Returns the result of calc_E_t, which is {E_t, E_t_b, E_t_d, E_t_r}
   */
  calc_E_t(wall, hrIndex) {
    let ctx = this.ctx;
    ctx.startSection("Calc E_t")
    let locData = ctx.toplevelData.locationData;
    ctx.wallDir = wall.direction.value
    ctx.groundReflectance = lookupData(ctx.buildingAndEnv.summerGroundReflectances, [ctx.wallDir])
    ctx.E_t_data = ctx.call(solar.calc_E_t,
      locData.timezone,
      hrIndex,
      ctx.toplevelData.dayOfYear,
      locData.latitude,
      locData.longitude, 
      locData.Tau_b,
      locData.Tau_d,
      wall.direction.value,
      wall.getTiltAngleDegs(),
      ctx.groundReflectance,
    );
    let res = ctx.E_t_data
    ctx.endSection()
    return res
  }

  calc_t_e(wall, hrIndex, t_out, alpha) {
    let ctx = this.ctx;
    ctx.startSection("Calc t_e")
    ctx.E_t = this.calc_E_t(wall, hrIndex).E_t
    ctx.t_e = ctx.eval('t_out + alpha*E_t/h_o + epsilon*deltaR/h_o', {
      t_out: t_out,
      alpha: alpha,
      h_o: 3.0,
      epsilon: 1,
      deltaR: !wall.isRoof() ? 0 : 20,
    }, 't_e')
    let res = ctx.t_e
    ctx.endSection()
    return res
  }

  /**
   * 
   * isWall - true if wall, false if roof
   */
  async calcWallLoads(wall) {
    let ctx = this.ctx
    ctx.startSection(`${wall.isRoof() ? 'Roof' : 'Wall'} Loads`)
    ctx.U = 1.0 / wall.getWallType().getRValue()
    ctx.alpha = wall.getWallType().getAbsorptance()
    ctx.A = wall.getStrictlyWallArea()
    ctx.W_loads = math.zeros(12, 24)
    for (let monthIndex = 0; monthIndex < 12; ++monthIndex) {
      ctx.startSection(`Month ${monthIndex}`)
      for (let hrIndex = 0; hrIndex < 24; ++hrIndex) {
        ctx.startSection(`Hour ${hrIndex}`)
        ctx.setProgressText(`Calculating ${wall.isRoof() ? 'roof' : 'wall'} loads - Month ${monthIndex}, Hour ${hrIndex}`)
        await ctx.briefWait()
        ctx.t_in = this.space.getSummerIndoorTemp(ctx, hrIndex)
        ctx.t_out = this.space.getSummerOutdoorTemp(ctx, monthIndex, hrIndex)
        ctx.t_e = this.calc_t_e(wall, hrIndex, ctx.t_out, ctx.alpha)
        ctx.W_mo_hr = ctx.eval('U*A*(t_e - t_in)', {}, 'W_mo_hr')
        ctx.W_loads.set([monthIndex, hrIndex], ctx.W_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }
    let res = ctx.W_loads
    ctx.logLoadMatrix('W_loads', ctx.W_loads)
    ctx.endSection()
    return res
  }

  /**
   * 
   * isWall: true if wall, false if roof
   */
  async calcWallSensibleLoads(isWall) {
    let ctx = this.ctx;
    ctx.startSection(isWall ? "Walls" : "Roofs")

    let timeSeriesData = ctx.tablesCache.getTable('TimeSeriesData');
    let walls = isWall ? this.space.walls : this.space.roofs;
    ctx.Walls_conv = math.zeros(12, 24)
    ctx.Walls_rad_nonsolar = math.zeros(12, 24)
    ctx.Walls_plenum = math.zeros(12, 24)
    for (let i = 0; i < walls.length; ++i) {
      let wall = walls[i];
      ctx.startSection(`${isWall ? 'Wall' : 'Roof'} ${i}`)
      ctx.F_plenum = wall.getPlenumLoadFraction();
      ctx.Wall_loads = await this.calcWallLoads(wall)

      if (isWall) {
        ctx.wallCTSVec = timeSeriesData.getCTSValuesForWallType(wall.getWallType())
      } else {
        ctx.wallCTSVec = timeSeriesData.getCTSValuesForRoofType(wall.getRoofType())
      }
      ctx.Wall_CTS = TimeSeriesUtils.transformMatrix(ctx, ctx.Wall_loads, ctx.wallCTSVec)

      ctx.F_conv_wall = isWall ? 0.54 : 0.40
      ctx.Wall_conv = ctx.eval('(1 - F_plenum)*Wall_CTS*F_conv_wall', {
      }, 'W_conv')
      ctx.Walls_conv = math.add(ctx.Walls_conv, ctx.Wall_conv)

      ctx.Wall_rad_nonsolar = ctx.eval('(1 - F_plenum)*Wall_CTS*(1 - F_conv_wall)', {
      }, 'W_rad_nonsolar')
      ctx.Walls_rad_nonsolar = math.add(ctx.Walls_rad_nonsolar, ctx.Wall_rad_nonsolar)

      ctx.Wall_plenum = ctx.eval('F_plenum*Wall_CTS', {}, 'W_plenum')
      ctx.Walls_plenum = math.add(ctx.Walls_plenum, ctx.Wall_plenum)

      ctx.endSection()
    }
    // TODO - Walls_rad_nosolar must go through the RTS transform

    ctx.logLoadMatrix('Walls_conv', ctx.Walls_conv)
    ctx.logLoadMatrix('Walls_rad_nonsolar', ctx.Walls_rad_nonsolar)
    ctx.logLoadMatrix('Walls_plenum', ctx.Walls_plenum)

    ctx.endSection()
  }

  async calcWindowNonSolarLoad(window) {
    let ctx = this.ctx;
    ctx.startSection("Window Nonsolar Load")

    ctx.Window_loads = math.zeros(12, 24)
    let windowType = window.getWindowType()
    ctx.U = windowType.computeUValue().uValue
    ctx.A = windowType.getArea()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating window nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.t_in = this.space.getSummerIndoorTemp(ctx, hr)
        ctx.t_out = this.space.getSummerOutdoorTemp(ctx, mo, hr)
        ctx.Window_mo_hr = ctx.eval('N*U*A*(t_out - t_in)', {
          N: window.quantity.value,
        }, 'Window_mo_hr')
        ctx.Window_loads.set([mo, hr], ctx.Window_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = ctx.Window_loads
    ctx.logLoadMatrix('loads', ctx.Window_loads)

    ctx.endSection()
    return res
  }

  calcWindowShadedAreas(windowOrDoor, solarAngles) {
    let ctx = this.ctx;
    ctx.startSection(`${windowOrDoor.getTypeName()} Shaded Areas`)

    let windowType = windowOrDoor.getWindowType()
    let optInShadingType = windowOrDoor.getInteriorShadingType()
    let optExShadingType = windowOrDoor.getExteriorShadingType()

    ctx.log(`Window type: ${windowType.name.value}`)
    ctx.log(`Internal shading type: ${optInShadingType ? optInShadingType.name.value : 'None'}`)
    ctx.log(`External shading type: ${optExShadingType ? optExShadingType.name.value : 'None'}`)

    ctx.W = windowType.width.value
    ctx.H = windowType.height.value

    if (!optExShadingType) {
      // No external shading
      ctx.log(`No external shading. Setting H_SL = H, W_SL = W`)
      ctx.H_SL = ctx.H
      ctx.W_SL = ctx.W
    } else {
      ctx.log(`Calculating H_SL and W_SL for external shading`)
      // Height of sunlight portion of the window
      ctx.P_H = optExShadingType.horizontalFinDepth.getValueInUnits(Units.ft)
      ctx.R_H = optExShadingType.horizontalFinDist.getValueInUnits(Units.ft)

      // Calculate Omega_V_rads
      ctx.Omega_V_rads = ctx.eval('arctan(tan(beta_rads) / cos(gamma_rads))', {
        arctan: Math.atan,
        beta_rads: solarAngles.beta_rads,
        gamma_rads: solarAngles.gamma_rads,
      }, 'Omega_V_rads')
      ctx.S_H = ctx.eval('P_H*tan(Omega_V_rads)', {
      }, 'S_H')
      ctx.H_SL = ctx.eval('max(0, min(H, H - (S_H - R_H)))', {
      }, 'H_SL')
      // Width of sunlit portion of the window
      if (solarAngles.gamma_rads <= 0) {
        // Use right fin
        ctx.P_W = optExShadingType.rightFinDepth.getValueInUnits(Units.ft)
        ctx.R_W = optExShadingType.rightFinDist.getValueInUnits(Units.ft)
      } else {
        // Use left fin
        ctx.P_W = optExShadingType.leftFinDepth.getValueInUnits(Units.ft)
        ctx.R_W = optExShadingType.leftFinDist.getValueInUnits(Units.ft)
      }
      ctx.S_W = ctx.eval('P_W*abs(tan(gamma_rads))', {
        gamma_rads: solarAngles.gamma_rads,
      }, 'S_W')
      ctx.W_SL = ctx.eval('max(0, min(W, W - (S_W - R_W)))', {
      }, 'W_SL')
    }

    // Calc the 4 result areas
    let isHorizontal = optInShadingType !== null && optInShadingType.orientation.value === ShadesOrientation.Horizontal
    if (isHorizontal) {
      ctx.log("Calculating shaded areas - horizontal internal shading (or none)")
      if (optInShadingType !== null) {
        // TODO - use schedule
        ctx.H_clear = ctx.H
      } else {
        ctx.H_clear = ctx.H
      }

      ctx.A_clear_light = ctx.eval('min(H_clear, H_SL)*W_SL', {
      }, 'A_clear_light')
      ctx.A_clear_shade = ctx.eval('max(0, H_clear - H_SL)*W + min(H_clear,H_SL)*(W - W_SL)', {
      }, 'A_clear_shade')
      ctx.A_shade_light = ctx.eval('max(0, H_SL - H_clear)*W_SL', {
      }, 'A_shade_light')
      ctx.A_shade_shade = ctx.eval('(H - max(H_SL,H_clear))*W + max(0, H_SL - H_clear)*(W - W_SL)', {
      }, 'A_shade_shade')
    } else {
      ctx.log("Calculating shaded areas - vertical internal shading")
      if (optInShadingType !== null) {
        // TODO - use schedule
        ctx.W_clear = ctx.W
      } else {
        ctx.W_clear = ctx.W
      }
      ctx.A_clear_light = ctx.eval('H_SL*(W_SL - 0.5*(W - W_clear) - max(0, 0.5*(W - W_clear) - (W - W_SL)))', {
      }, 'A_clear_light')
      ctx.A_clear_shade = ctx.eval('(H - H_SL)*(W_SL - 0.5*(W - W_clear) - ' +
        'max(0, 0.5*(W - W_clear) - (W - W_SL))) + ' +
        'H*(max(0, (W - W_SL) - 0.5*(W - W_clear)))', {
      }, 'A_clear_shade')
      ctx.A_shade_light = ctx.eval('H_SL*(0.5*(W - W_clear) + max(0, 0.5*(W - W_clear) - W_SL))', {
      }, 'A_shade_light')
      ctx.A_shade_shade = ctx.eval('H*min(0.5*(W - W_clear), W - W_SL) + ' + 
        '(H - H_SL)*(0.5*(W - W_clear)+max(0, 0.5*(W - W_clear) - (W - W_SL)))', {
      }, 'A_shade_shade')
    }

    let fracGlass = windowOrDoor.getPercentGlass() / 100.0
    if (fracGlass < 1) {
      ctx.fracGlass = fracGlass
      ctx.A_clear_light *= ctx.fracGlass
      ctx.A_clear_shade *= ctx.fracGlass
      ctx.A_shade_light *= ctx.fracGlass
      ctx.A_shade_shade *= ctx.fracGlass
    }

    let res = {
      A_clear_light: ctx.A_clear_light,
      A_shade_light: ctx.A_shade_light,
      A_clear_shade: ctx.A_clear_shade,
      A_shade_shade: ctx.A_shade_shade,
    }
    ctx.endSection()
    return res
  }

  /**
   * This function is also used for door solar loads.
   * Can be passed a WallWindor or WallDoor
   * 
   */
  async calcWindowSolarLoad(wall, windowOrDoor) {
    let ctx = this.ctx;
    ctx.startSection(`${windowOrDoor.getTypeName()} Solar Load`)

    ctx.Window_loads = math.zeros(12, 24)
    let windowType = windowOrDoor.getWindowType()
    let optInShadingType = windowOrDoor.getInteriorShadingType()
    let iacCalculator = new IACCalculator(
      windowType,
      optInShadingType,
      gApp.proj().windowsData.iacValues,
    )
    ctx.SHGC = windowOrDoor.computeShgc()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        console.log(`Calculating ${windowOrDoor.getTypeName()} solar loads - Month ${mo}, Hour ${hr}`)
        ctx.setProgressText(`Calculating ${windowOrDoor.getTypeName()} solar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.E_t_data = this.calc_E_t(wall, hr)
        ctx.SHGC_angle = this.calcSHGCForTime(ctx.SHGC, mo, hr)
        let solarAngles = {
          beta_rads: ctx.E_t_data.beta_rads,
          gamma_rads: ctx.E_t_data.gamma_rads,
          theta_degs: ctx.E_t_data.theta_degs,
        };
        if (optInShadingType !== null) {
          ctx.IAC_res = iacCalculator.computeIACForSolarPosition(ctx, solarAngles)
        } else {
          ctx.IAC_res = {iac: 1, iacDiff: 1}
        }
        let shadedAreas = this.calcWindowShadedAreas(windowOrDoor, solarAngles)
        ctx.Window_mo_hr = ctx.eval(
          'E_t_b*SHGC_angle*(A_clear_light + A_shade_light*IAC)' +
          ' + (E_t_d+E_t_r)*SHGC_D*(A_clear_shade+A_shade_shade*IAC_D)', {
            E_t_b: ctx.E_t_data.E_t_b,
            E_t_d: ctx.E_t_data.E_t_d,
            E_t_r: ctx.E_t_data.E_t_r,
            SHGC_D: ctx.SHGC.Diffuse,
            IAC: ctx.IAC_res.iac,
            IAC_D: ctx.IAC_res.iacDiff,
            A_clear_light: shadedAreas.A_clear_light,
            A_shade_light: shadedAreas.A_shade_light,
            A_clear_shade: shadedAreas.A_clear_shade,
            A_shade_shade: shadedAreas.A_shade_shade,
        }, 'Window_mo_hr')
        ctx.Window_loads.set([mo, hr], ctx.Window_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = ctx.Window_loads
    ctx.logLoadMatrix('Solar loads', ctx.Window_loads)

    ctx.endSection()
    return res
  }

  async calcWindowSensibleLoads() {
    console.log("Calculating window sensible loads")
    let ctx = this.ctx;
    ctx.startSection("Windows")

    ctx.Windows_conv = math.zeros(12, 24)
    ctx.Windows_rad_nonsolar = math.zeros(12, 24)
    ctx.Windows_rad_solar = math.zeros(12, 24)

    let walls = this.space.walls;
    for (let wallIndex = 0; wallIndex < walls.length; ++wallIndex) {
      let wall = walls[wallIndex];
      for (let winIndex = 0; winIndex < wall.windows.length; ++winIndex) {
        let window = wall.windows[winIndex];
        ctx.startSection(`Wall ${wallIndex} - Window ${winIndex}`)
        ctx.Window_load = await this.calcWindowNonSolarLoad(window)

        ctx.SHGC = window.getWindowType().computeShgc().shgc
        ctx.F_conv_window = ctx.SHGC > 0.5 ? 0.67 : 0.54
        ctx.Window_conv = ctx.eval('Window_load*F_conv_window', {}, 'Window_conv')
        ctx.Windows_conv = math.add(ctx.Windows_conv, ctx.Window_conv)

        ctx.Window_rad_nonsolar = ctx.eval('Window_load*(1 - F_conv_window)', {}, 'Window_rad_nonsolar')
        ctx.Windows_rad_nonsolar = math.add(ctx.Windows_rad_nonsolar, ctx.Window_rad_nonsolar)

        ctx.Window_rad_solar = await this.calcWindowSolarLoad(wall, window)
        ctx.Windows_rad_solar = math.add(ctx.Windows_rad_solar, ctx.Window_rad_solar)

        ctx.endSection()
      }
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('Windows_conv', ctx.Windows_conv)
    ctx.logLoadMatrix('Windows_rad_nonsolar', ctx.Windows_rad_nonsolar)
    ctx.logLoadMatrix('Windows_rad_solar', ctx.Windows_rad_solar);

    ctx.endSection()
  }

  async calcDoorNonSolarLoad(wall, door) {
    let ctx = this.ctx;
    ctx.startSection("Door Nonsolar Load")

    ctx.Door_loads_glass = math.zeros(12, 24)
    ctx.Door_loads_opaq = math.zeros(12, 24)

    let doorType = door.getDoorType()
    let uValue = doorType.computeUValue()
    ctx.U_glass = uValue.uValueGlass
    ctx.U_opaq = uValue.uValueDoor
    ctx.A_glass = doorType.getGlassArea()
    ctx.A_opaq = doorType.getOpaqueArea()

    ctx.doorAlpha = lookupData(DoorAlphaMap, [doorType.colour.value])
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating door nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.t_in = this.space.getSummerIndoorTemp(ctx, hr)
        ctx.t_out = this.space.getSummerOutdoorTemp(ctx, mo, hr)
        ctx.t_e = this.calc_t_e(wall, hr, ctx.t_out, ctx.doorAlpha)
        ctx.Door_mo_hr_glass = ctx.eval('N*U_glass*A_glass*(t_out - t_in)', {
          N: door.quantity.value,
        }, 'Door_mo_hr_glass')
        ctx.Door_mo_hr_opaq = ctx.eval('N*U_opaq*A_opaq*(t_e - t_i)', {
          N: door.quantity.value,
        }, 'Door_mo_hr_opaq')
        ctx.Door_loads_glass.set([mo, hr], ctx.Door_mo_hr_glass)
        ctx.Door_loads_opaq.set([mo, hr], ctx.Door_mo_hr_opaq)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = {
      glass: ctx.Door_loads_glass,
      opaq: ctx.Door_loads_opaq,
    }
    ctx.logLoadMatrix('Door_loads_glass', ctx.Door_loads_glass)
    ctx.logLoadMatrix('Door_loads_opaq', ctx.Door_loads_opaq)

    ctx.endSection()
    return res
  }

  async calcDoorSolarLoad(wall, door) {
    let ctx = this.ctx;
    ctx.startSection("Door Solar Load")

    let res = null
    let doorType = door.getDoorType()
    let percentGlass = doorType.getPercentGlass()
    if (percentGlass > 0) {
      res = await this.calcWindowSolarLoad(wall, door)
    } else {
      // No glass, skip calculations
      res = math.zeros(12, 24)
    }

    ctx.endSection()
    return res
  }

  async calcDoorSensibleLoads() {
    console.log("Calculating door sensible loads")
    let ctx = this.ctx;
    ctx.startSection("Doors")

    ctx.Doors_conv = math.zeros(12, 24)
    ctx.Doors_rad_nonsolar = math.zeros(12, 24)
    ctx.Doors_rad_solar = math.zeros(12, 24)

    let walls = this.space.walls;
    for (let wallIndex = 0; wallIndex < walls.length; ++wallIndex) {
      let wall = walls[wallIndex];
      for (let doorIndex = 0; doorIndex < wall.doors.length; ++doorIndex) {
        let door = wall.doors[doorIndex];
        ctx.startSection(`Wall ${wallIndex} - Door ${doorIndex}`)
        let nonSolarDoorLoads = await this.calcDoorNonSolarLoad(wall, door)
        ctx.Door_loads_glass = nonSolarDoorLoads.glass
        ctx.Door_loads_opaq = nonSolarDoorLoads.opaq

        // Note: the door does not have a separate SHGC for glass and frame.
        ctx.SHGC = door.getDoorType().computeShgc().shgc;
        ctx.F_conv_wall = 0.54
        ctx.F_conv_window = ctx.SHGC > 0.5 ? 0.67 : 0.54
        ctx.Door_conv = ctx.eval('Door_loads_opaq*F_conv_wall + Door_loads_glass*F_conv_window', {
        }, 'Door_conv')
        ctx.Doors_conv = math.add(ctx.Doors_conv, ctx.Door_conv)

        ctx.Door_rad_nonsolar = ctx.eval('Door_loads_opaq*(1-F_conv_wall) + Door_loads_glass*(1-F_conv_window)', {}, 'Door_rad_nonsolar')
        ctx.Doors_rad_nonsolar = math.add(ctx.Doors_rad_nonsolar, ctx.Door_rad_nonsolar)

        ctx.Door_rad_solar = await this.calcDoorSolarLoad(wall, door)
        ctx.Doors_rad_solar = math.add(ctx.Doors_rad_solar, ctx.Door_rad_solar)

        ctx.endSection()
      }
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('Doors_conv', ctx.Doors_conv)
    ctx.logLoadMatrix('Doors_rad_nonsolar', ctx.Doors_rad_nonsolar)
    ctx.logLoadMatrix('Doors_rad_solar', ctx.Doors_rad_solar);

    ctx.endSection()
  }

  async calcSkylightNonSolarLoad(skylight) {
    let ctx = this.ctx;
    ctx.startSection("Skylight Nonsolar Load")

    ctx.Skylight_loads = math.zeros(12, 24)
    let skylightType = skylight.getSkylightType()
    ctx.U = skylightType.computeUValue().uValue
    ctx.A = skylightType.getArea()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating skylight nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.t_in = this.space.getSummerIndoorTemp(ctx, hr)
        ctx.t_out = this.space.getSummerOutdoorTemp(ctx, mo, hr)
        ctx.Skylight_mo_hr = ctx.eval('N*U*A*(t_out - t_in)', {
          N: skylight.quantity.value,
        }, 'Skylight_mo_hr')
        ctx.Skylight_loads.set([mo, hr], ctx.Skylight_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = ctx.Skylight_loads
    ctx.logLoadMatrix('loads', ctx.Skylight_loads)

    ctx.endSection()
    return res
  }

  calcSHGCForTime(shgcs, mo, hr) {
    let ctx = this.ctx;
    ctx.startSection("SHGC for time")
    // TODO - how to do?
    /*
    ctx.SHGC_angle = ctx.eval('SHGC*cos(2*PI*(hr + 1)/24)', {
      SHGC: shgc,
      hr: hr,
    }, 'SHGC_angle')
    */
    ctx.SHGC_angle = shgcs.shgc
    let res = ctx.SHGC_angle
    ctx.endSection()
    return res
  }

  async calcSkylightSolarLoad(roof, skylight) {
    let ctx = this.ctx;
    ctx.startSection("Skylight Solar Load")

    ctx.Skylight_loads = math.zeros(12, 24)
    let skylightType = skylight.getSkylightType()
    ctx.SHGC = skylightType.computeShgc()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating skylight solar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.E_t = this.calc_E_t(roof, hr).E_t
        ctx.SHGC_angle = this.calcSHGCForTime(ctx.SHGC, mo, hr)
        ctx.Skylight_mo_hr = ctx.eval('E_t*SHGC_angle', {
        }, 'Skylight_mo_hr')
        ctx.Skylight_loads.set([mo, hr], ctx.Skylight_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = ctx.Skylight_loads
    ctx.logLoadMatrix('Solar loads', ctx.Skylight_loads)

    ctx.endSection()
    return res
  }

  async calcSkylightSensibleLoads() {
    console.log("Calculating skylight sensible loads")
    let ctx = this.ctx;
    ctx.startSection("Skylights")

    ctx.Skylights_conv = math.zeros(12, 24)
    ctx.Skylights_rad_nonsolar = math.zeros(12, 24)
    ctx.Skylights_rad_solar = math.zeros(12, 24)

    let roofs = this.space.roofs;
    for (let roofIndex = 0; roofIndex < roofs.length; ++roofIndex) {
      let roof = roofs[roofIndex];
      for (let skylightIndex = 0; skylightIndex < roof.skylights.length; ++skylightIndex) {
        let skylight = roof.skylights[skylightIndex];
        ctx.startSection(`Roof ${roofIndex} - Skylight ${skylightIndex}`)
        ctx.Skylight_load = await this.calcSkylightNonSolarLoad(skylight)

        ctx.SHGC = skylight.getSkylightType().computeShgc().shgc
        ctx.F_conv_window = ctx.SHGC > 0.5 ? 0.67 : 0.54
        ctx.Skylight_conv = ctx.eval('Skylight_load*F_conv_window', {}, 'Skylight_conv')
        ctx.Skylights_conv = math.add(ctx.Skylights_conv, ctx.Skylight_conv)

        ctx.Skylight_rad_nonsolar = ctx.eval('Skylight_load*(1 - F_conv_window)', {}, 'Skylight_rad_nonsolar')
        ctx.Skylights_rad_nonsolar = math.add(ctx.Skylights_rad_nonsolar, ctx.Skylight_rad_nonsolar)

        ctx.Skylight_rad_solar = await this.calcSkylightSolarLoad(roof, skylight)
        ctx.Skylights_rad_solar = math.add(ctx.Skylights_rad_solar, ctx.Skylight_rad_solar)

        ctx.endSection()
      }
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('Skylights_conv', ctx.Skylights_conv)
    ctx.logLoadMatrix('Skylights_rad_nonsolar', ctx.Skylights_rad_nonsolar)
    ctx.logLoadMatrix('Skylights_rad_solar', ctx.Skylights_rad_solar);

    ctx.endSection()
  }

  async calcApplianceSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating appliance sensible loads")
    ctx.startSection(`Appliance Sensible Loads`)

    let appliances = this.space.internals.appliances;

    ctx.A_conv_appliances = math.zeros(12, 24)
    ctx.A_rad_appliances = math.zeros(12, 24)

    ctx.A_conv_appliances_row = makeVector(24)
    ctx.A_rad_appliances_row = makeVector(24)

    for (let i = 0; i < appliances.appliances.length; ++i) {
      let appliance = appliances.appliances[i];
      ctx.startSection(`Appliance ${i}`)
      let applianceData = appliance.getApplianceData(ctx)
      ctx.S_app = applianceData.S_app
      ctx.F_conv = applianceData.F_conv
      ctx.F_rad = applianceData.F_rad
      ctx.appSched = appliance.getSchedule().getData()
      ctx.D = appliance.getDiversityFactor()
      ctx.N = appliance.quantity.value
      for (let hr = 0; hr < 24; hr++) {
        ctx.startSection(`Hour ${hr}`)
        ctx.conv_appliance = ctx.eval('N*S_app*sched*D*F_conv', {
          sched: ctx.appSched[hr],
        }, 'conv_appliance')
        ctx.rad_appliance = ctx.eval('N*S_app*sched*D*F_rad', {
          sched: ctx.appSched[hr],
        }, 'rad_appliance')

        ctx.A_conv_appliances_row[hr] += ctx.conv_appliance
        ctx.A_rad_appliances_row[hr] += ctx.rad_appliance

        ctx.endSection()
      }
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.A_conv_appliances, mo, ctx.A_conv_appliances_row)
      MatrixUtils.setRow(ctx.A_rad_appliances, mo, ctx.A_rad_appliances_row)
    } 

    ctx.log("Results:")
    ctx.logLoadMatrix('A_conv_appliances', ctx.A_conv_appliances)
    ctx.logLoadMatrix('A_rad_appliances', ctx.A_rad_appliances)

    ctx.endSection()
  }

  async calcLightingSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating lighting sensible loads")
    ctx.startSection(`Lighting Sensible Loads`)
    let lighting = this.space.internals.lighting;

    ctx.A_conv_lights = math.zeros(12, 24)
    ctx.A_rad_lights = math.zeros(12, 24)
    ctx.A_plenum_lights = math.zeros(12, 24)

    ctx.A_conv_lights_row = makeVector(24)
    ctx.A_rad_lights_row = makeVector(24)
    ctx.A_plenum_lights_row = makeVector(24)

    ctx.L = lighting.getPower()
    ctx.D = lighting.getDiversityFactor()
    ctx.luminaireType = lighting.getLuminaireType()
    let luminaireData = lighting.getLuminaireData(ctx)
    ctx.F_conv = luminaireData.F_conv
    ctx.F_space = luminaireData.F_space
    let sched = lighting.getSchedule().getData()
    for (let hr = 0; hr < 24; hr++) {
      ctx.startSection(`Hour ${hr}`)
      ctx.conv_load = ctx.eval('L*sched*D*F_conv*F_space', {
        sched: sched[hr],
      }, 'conv_load')
      ctx.rad_load = ctx.eval('L*sched*D*(1-F_conv)*F_space', {
        sched: sched[hr],
      }, 'rad_load')
      ctx.plenum_load = ctx.eval('L*sched*D*(1-F_space)', {
        sched: sched[hr],
      }, 'plenum_load')
      ctx.A_conv_lights_row[hr] = ctx.conv_load
      ctx.A_rad_lights_row[hr] = ctx.rad_load
      ctx.A_plenum_lights_row[hr] = ctx.plenum_load
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.A_conv_lights, mo, ctx.A_conv_lights_row)
      MatrixUtils.setRow(ctx.A_rad_lights, mo, ctx.A_rad_lights_row)
      MatrixUtils.setRow(ctx.A_plenum_lights, mo, ctx.A_plenum_lights_row)
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('A_conv_lights', ctx.A_conv_lights)
    ctx.logLoadMatrix('A_rad_lights', ctx.A_rad_lights)
    ctx.logLoadMatrix('A_plenum_lights', ctx.A_plenum_lights)

    ctx.endSection()
  }

  async calcPeopleSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating people sensible loads")
    ctx.startSection(`People Sensible Loads`)
    let people = this.space.internals.people;

    ctx.A_conv_people = math.zeros(12, 24)
    ctx.A_rad_people = math.zeros(12, 24)

    ctx.A_conv_people_row = makeVector(24)
    ctx.A_rad_people_row = makeVector(24)

    ctx.M = people.getNumOccupants()
    ctx.activityLevel = people.getActivityLevel()
    let activityLevelData = people.getActivityLevelData(ctx)
    ctx.S_P = activityLevelData.S_P
    ctx.F_conv = activityLevelData.F_conv
    let sched = people.getSchedule().getData()
    for (let hr = 0; hr < 24; hr++) {
      ctx.startSection(`Hour ${hr}`)
      ctx.conv_load = ctx.eval('S_P*sched*M*F_conv', {
        sched: sched[hr],
      }, 'conv_load')
      ctx.rad_load = ctx.eval('S_P*sched*M*(1 - F_conv)', {
        sched: sched[hr],
      }, 'rad_load')
      ctx.A_conv_people_row[hr] = ctx.conv_load
      ctx.A_rad_people_row[hr] = ctx.rad_load
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.A_conv_people, mo, ctx.A_conv_people_row)
      MatrixUtils.setRow(ctx.A_rad_people, mo, ctx.A_rad_people_row)
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('A_conv_people', ctx.A_conv_people)
    ctx.logLoadMatrix('A_rad_people', ctx.A_rad_people)

    ctx.endSection()
  }

  async calcMiscSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating miscellaneous sensible loads")
    ctx.startSection(`Miscellaneous Sensible Loads`)

    let miscLoads = this.space.internals.miscLoads;

    let M_sens_rad = math.zeros(12, 24)
    let M_sens_conv = math.zeros(12, 24)

    let M_sens_rad_row = makeVector(24)
    let M_sens_conv_row = makeVector(24)

    ctx.M_sens = miscLoads.getSensibleLoad(ctx)
    ctx.F_rad = miscLoads.getSensibleLoadRadiantFraction()
    let sched = miscLoads.getSchedule().getData()
    for (let hr = 0; hr < 24; hr++) {
      ctx.startSection(`Hour ${hr}`)
      ctx.sens_rad = ctx.eval('M_sens*F_rad*sched', {
        sched: sched[hr],
      }, 'sens_rad')
      ctx.sens_conv = ctx.eval('M_sens*(1 - F_rad)*sched', {
        sched: sched[hr],
      }, 'sens_conv')
      M_sens_rad_row[hr] = ctx.sens_rad
      M_sens_conv_row[hr] = ctx.sens_conv
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(M_sens_rad, mo, M_sens_rad_row)
      MatrixUtils.setRow(M_sens_conv, mo, M_sens_conv_row)
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('M_sens_rad', M_sens_rad)
    ctx.logLoadMatrix('M_sens_conv', M_sens_conv)

    ctx.endSection()
  }

  async calcInfiltrationSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating infiltration sensible loads")
    ctx.startSection(`Infiltration Sensible Loads`)

    ctx.A_sens_inf = math.zeros(12, 24)

    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Summer);
    let onlyDuringOccupiedHours = this.space.infiltrationHours.value == InfiltrationHours.OccupiedHours;
    ctx.log(`Only during occupied hours: ${onlyDuringOccupiedHours}`)
    ctx.occupancySchedule = this.space.internals.people.getSchedule().getData();
    // TODO - adjust C_s for altitude
    ctx.C_s = 1.10
    for (let mo = 0; mo < 12; mo++) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; hr++) {
        ctx.startSection(`Hour ${hr}`)
        ctx.t_in = this.space.getSummerIndoorTemp(ctx, hr)
        ctx.t_out = this.space.getSummerOutdoorTemp(ctx, mo, hr)
        ctx.sched = ctx.occupancySchedule[hr] > 0 ? 1 : 0
        ctx.sens_inf = ctx.eval('Q_inf*sched*C_s*(t_out - t_in)', {
        }, 'sens_inf')
        ctx.A_sens_inf.set([mo, hr], ctx.sens_inf)
        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.log("Results:")
    ctx.logLoadMatrix('A_sens_inf', ctx.A_sens_inf)

    ctx.endSection()
  }

  async calcLatentLoads() {
    console.log("Calculating latent loads")
    let ctx = this.ctx;
    ctx.startSection("Latent Loads")
    ctx.I_lat = await this.calcInfiltrationLatentLoads()
    await ctx.briefWait()
    ctx.A_lat = await this.calcApplianceLatentLoads()
    await ctx.briefWait()
    ctx.P_lat = await this.calcPeopleLatentLoads()
    await ctx.briefWait()
    ctx.M_lat = await this.calcMiscLatentLoads()
    await ctx.briefWait()
    ctx.space_lat = ctx.eval('I_lat + A_lat + P_lat + M_lat', {
    }, 'space_lat')
    ctx.logLoadMatrix('space_lat', ctx.space_lat)
    ctx.endSection()
  }

  async calcSensibleLoads() {
    let ctx = this.ctx;
    ctx.startSection("Sensible Loads")
    await this.calcWallSensibleLoads(true)
    await ctx.briefWait()
    await this.calcWallSensibleLoads(false)
    await ctx.briefWait()
    await this.calcWindowSensibleLoads()
    await ctx.briefWait()
    await this.calcDoorSensibleLoads()
    await ctx.briefWait()
    await this.calcSkylightSensibleLoads()
    await ctx.briefWait()

    //await this.calcApplianceSensibleLoads()
    await ctx.briefWait()
    await this.calcLightingSensibleLoads()
    await ctx.briefWait()
    await this.calcPeopleSensibleLoads()
    await ctx.briefWait()
    await this.calcMiscSensibleLoads()
    await ctx.briefWait()
    await this.calcInfiltrationSensibleLoads()
    await ctx.briefWait()
    ctx.endSection()
  }

  async calcOutputs() {
    let ctx = this.ctx;
    ctx.startSection('Space Cooling')

    if (valOr(ctx.debugOptions.Calc_latent_loads, true)) {
      await this.calcLatentLoads()
    }
    if (valOr(ctx.debugOptions.Calc_wall_loads, true)) {
      await this.calcSensibleLoads()
    }

    // TODO - add up all the sensible loads
    let res = {
      q_sensible: 0,
    }
    ctx.endSection()
    return res
  }
};
setupClass(SpaceCoolingCalculator)
