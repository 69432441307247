import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { arraysEqual } from './SharedUtils.js'
import * as ser from './SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from './Field.js'
import { FieldWithVariableUnits, MultiTieredSelect, } from './FieldUtils.js'
import { Wall, Roof, Partition, HouseFloor,
  AutomaticOrManual, YesNo, makeNoYesField, makeYesNoField } from './Components.js'
import { Units } from './Units.js'

import { GetLightingPowerTable, LightingPower } from './LightingPower.js'
import { LuminaireType } from './LuminaireData.js'

import { gApp, DebugOn } from './State.js'

import * as appliance from './ApplianceLoadsData.js'
import { GetOccupancyTableData } from './SpaceTypes.js'
import { GetMotorEfficiencyData } from './MaterialData/MotorEfficiency.js'

let OccupancyEntryType = makeEnum({
  ByPeople: 'Enter # of people',
  BySpaceDensity: 'Enter space density',
})

/*
Activity Level
Seated	
Seated, very light work	
Moderately active office work	
Standing, light work; walking	
Walking, standing	
Sedentary work	
Light bench work	
Moderate dancing	
Walking 3 mph; light machine work	
Bowling	
Heavy work	
Heavy machine work; lifting	
Athletics	
*/
let ActivityLevel = makeEnum({
  Seated: 'Seated',
  SeatedVeryLightWork: 'Seated, very light work',
  ModeratelyActiveOfficeWork: 'Moderately active office work',
  StandingLightWorkWalking: 'Standing, light work; walking',
  WalkingStanding: 'Walking, standing',
  SedentaryWork: 'Sedentary work',
  LightBenchWork: 'Light bench work',
  ModerateDancing: 'Moderate dancing',
  Walking3mphLightMachineWork: 'Walking 3 mph; light machine work',
  Bowling: 'Bowling',
  HeavyWork: 'Heavy work',
  HeavyMachineWorkLifting: 'Heavy machine work; lifting',
  Athletics: 'Athletics',
})

class PeopleInternals {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;

    this.occupancyEntryType = Field.makeSelect("Occupancy", OccupancyEntryType)
    this.occupancyCount = new Field({
      name: 'Occupancy Count',
      type: FieldType.People,
    })
    this.occupancyCount.setVisibility(() => {
      return this.occupancyEntryType.value == OccupancyEntryType.ByPeople
    })
    this.occupancyDensity = new Field({
      name: 'Occupancy Density',
      type: FieldType.PeoplePer1000ft2,
    })
    this.occupancyDensity.setVisibility(() => {
      return this.occupancyEntryType.value == OccupancyEntryType.BySpaceDensity
    })
    this.occupancyTableData = GetOccupancyTableData()

    this.activityLevel = Field.makeSelect('Activity Level', ActivityLevel);

    this.schedule = Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
      errorWhenEmpty: 'You must create a Schedule in the Schedules tab',
    })

    this.useDiversityFactor = makeYesNoField('Use diversity factor in peak calculations')
    this.diversityFactor = new Field({
      name: 'Diversity Factor',
      type: FieldType.Ratio,
    })
    this.diversityFactor.setVisibility(() => {
      return this.useDiversityFactor.value == YesNo.Yes
    })

    this.miscFields = [
      'activityLevel',
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]

    this.fields = [
      'occupancyEntryType',
      'occupancyCount',
      'occupancyDensity',
      'activityLevel',
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]
    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
  }

  getNumOccupants() {
    if (this.occupancyEntryType.value == OccupancyEntryType.ByPeople) {
      return this.occupancyCount.value
    } else if (this.occupancyEntryType.value == OccupancyEntryType.BySpaceDensity) {
      return this.spaceInternals.space.floorArea.value * this.occupancyDensity.value / 1000.0;
    } else {
      throw new Error("Unknown occupancy entry type: " + this.occupancyEntryType.value)
    }
  }

  getActivityLevel() {
    return this.activityLevel.value;
  }

  getActivityLevelData(ctx) {
    let activityLevelDataTable = ctx.tablesCache.getTable('ActivityLevels');
    // TODO - use table row ids instead of labels
    let activityLevel = ActivityLevel._labels[this.activityLevel.value]
    return activityLevelDataTable.getActivityLevelData(activityLevel);
  }

  getSchedule() {
    return this.schedule.lookupValue();
  }

  getDiversityFactor() {
    return this.useDiversityFactor.value == YesNo.Yes ? this.diversityFactor.value : 1.0;
  }
}
setupClass(PeopleInternals)

/*
Unknown
Metal halide / high-pressure sodium vapour (low-wattage)
Metal halide / high-pressure sodium vapour (high-wattage)
Incandescent
LED
*/
let LightType = makeEnum({
  Unknown: 'Unknown',
  MetalHalideHighPressureSodiumVapourLowWattage: 'Metal halide / high-pressure sodium vapour (low-wattage)',
  MetalHalideHighPressureSodiumVapourHighWattage: 'Metal halide / high-pressure sodium vapour (high-wattage)',
  Incandescent: 'Incandescent',
  LED: 'LED',
})

class LightingInternals {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;

    this.power = new FieldWithVariableUnits({
      name: 'Manual Value',
      type: FieldType.Load,
      units: Units.Load,
      unitOptions: [
        Units.Load,
        Units.LoadMetric,
        Units.LoadPerArea,
        Units.WattsPerFt2,
      ]
    })
    this.powerData = GetLightingPowerTable()

    this.type = Field.makeSelect('Type', LightType)
    this.luminaireType = Field.makeSelect('Luminaire type', LuminaireType)

    this.schedule = Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
      errorWhenEmpty: 'You must create a Schedule in the Schedules tab',
    })

    this.useDiversityFactor = makeYesNoField('Use diversity factor in peak calculations')
    this.diversityFactor = new Field({
      name: 'Diversity Factor',
      type: FieldType.Ratio,
    })
    this.diversityFactor.setVisibility(() => {
      return this.useDiversityFactor.value == YesNo.Yes
    })

    this.otherFields = [
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]

    this.fields = [
      'power',
      'type',
      'luminaireType',
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]
    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
  }

  getPower() {
    if (this.power.units == Units.Load) {
      return this.power.value
    } else if (this.power.units == Units.LoadMetric) {
      return this.power.getValueInUnits(Units.Load)
    } else if (this.power.units == Units.LoadPerArea) {
      return this.power.value * this.spaceInternals.space.floorArea.value;
    } else if (this.power.units == Units.WattsPerFt2) {
      return this.power.getValueInUnits(Units.LoadPerArea) * this.spaceInternals.space.floorArea.value;
    } else {
      throw new Error("Unknown power units: " + this.power.units)
    }
  }

  getLuminaireType() {
    return this.luminaireType.value;
  }

  getLuminaireData(ctx) {
    let luminaireDataTable = ctx.tablesCache.getTable('LuminairesData');
    //return luminaireDataTable.getLuminaireData(this.luminaireType.value);
    // TODO - modify the table to use ids
    let rowId = LuminaireType._labels[this.luminaireType.value]
    return luminaireDataTable.getLuminaireData(rowId);
  }

  getSchedule() {
    return this.schedule.lookupValue();
  }

  getDiversityFactor() {
    return this.useDiversityFactor.value == YesNo.Yes ? this.diversityFactor.value : 1.0;
  }
}
setupClass(LightingInternals)

class Motor {
  init() {
    this.power = new Field({
      name: 'Power',
      type: FieldType.Power,
    })
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
    })

    this.efficiency = new Field({
      name: 'Efficiency',
      type: FieldType.Percent,
    })
    this.efficiencyData = GetMotorEfficiencyData()
    
    this.schedule = Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
      errorWhenEmpty: 'You must create a Schedule in the Schedules tab',
    })
    this.useDiversityFactor = makeYesNoField('Use diversity factor in peak calculations')
    this.diversityFactor = new Field({
      name: 'Diversity Factor',
      type: FieldType.Ratio,
    })
    this.diversityFactor.setVisibility(() => {
      return this.useDiversityFactor.value == YesNo.Yes
    })

    this.otherFields = [
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]

    this.fields = [
      'power',
      'quantity',
      'efficiency',
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
  }
}
setupClass(Motor)

class MotorsInput {
  init() {
    this.motors = []

    this.serFields = [
      ser.arrayField('motors', () => { return Motor.create(); }),
    ]
    this.childObjs = '$auto'
  }

  addMotor() {
    this.motors.push(Motor.create())
  }
}
setupClass(MotorsInput)

let ApplianceLoadsEntryType = makeEnum({
  SelectAppliance: 'Select Appliance',
  SelectByOfficeLoadFactor: 'Select by office load factor',
  ManualEntry: 'Manual Entry',
})

class ApplianceEntry {
  init() {
    this.entryType = Field.makeSelect("Input type", ApplianceLoadsEntryType)

    this.applianceType = new MultiTieredSelect({
      name: 'Appliance Type',
      type: FieldType.Select,
      optionsMap: appliance.ApplianceOptionsMap,
    })
    this.applianceType.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.SelectAppliance
    })

    this.computerTypes = Field.makeSelect('Computer types', appliance.TypicalComputerTypes)
    this.officeLoadsIntensity = new Field({
      name: 'Office Loads Intensity',
      type: FieldType.Select,
      choices: []
    })
    this.officeLoadsIntensity.makeChoicesUpdater(() => {
      let options = lookupData(appliance.OfficeLoadFactors, [this.computerTypes.value]);
      return makeOptions(appliance.OfficeLoadIntensities, Object.keys(options))
    })
    this.computerTypes.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor
    })
    this.officeLoadsIntensity.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor
    })

    this.manualGroup = FieldGroup.fromDict({
      peakSensiblePowerOutput: new Field({
        name: 'Peak Sensible Power Output',
        type: FieldType.Load,
      }),
      peakLatentPowerOutput: new Field({
        name: 'Peak Latent Power Output',
        type: FieldType.Load,
      }),
      radiantSensiblePortion: new Field({
        name: 'Radiant Fraction of Sensible Load',
        type: FieldType.Ratio,
      }),
    })
    this.manualGroup.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.ManualEntry
    })

    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
    })

    this.schedule = Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
      errorWhenEmpty: 'You must create a Schedule in the Schedules tab',
    })

    this.useDiversityFactor = makeYesNoField('Use diversity factor in peak calculations')
    this.diversityFactor = new Field({
      name: 'Diversity Factor',
      type: FieldType.Ratio,
    })
    this.diversityFactor.setVisibility(() => {
      return this.useDiversityFactor.value == YesNo.Yes
    })

    this.serFields = [
      'entryType',
      'applianceType',
      'computerTypes',
      'officeLoadsIntensity',
      'manualGroup',
      'quantity',
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]
    this.childObjs = '$auto'
  }

  getQuantity() {
    return this.quantity.value
  }

  getSchedule() {
    return this.schedule.lookupValue();
  }

  getDiversityFactor() {
    return this.useDiversityFactor.value == YesNo.Yes ? this.diversityFactor.value : 1.0;
  }

  // Use to calculate appliance sensible loads
  getApplianceData(ctx) {
    let res = {}
    res.S_app = 0;
    res.F_rad = 0;
    res.F_conv = 0;

    if (this.entryType.value == ApplianceLoadsEntryType.ManualEntry) {
      res.S_app = this.manualGroup.getField('peakSensiblePowerOutput').value
      res.F_rad = this.manualGroup.getField('radiantSensiblePortion').value
      res.F_conv = 1 - res.F_rad
    } else if (this.entryType.value == ApplianceLoadsEntryType.SelectAppliance) {
      res = this._getApplianceDataForType(ctx)
    } else if (this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor) {
      res = this._getApplianceDataForSelectByOfficeLoadFactorType(ctx)
    } else {
      throw new Error("Unknown appliance entry type: " + this.entryType.value)
    }

    return res
  }

  _getApplianceDataForSelectApplianceType(ctx) {
    // TODO - the tables have to be cleaned up here.
    // Too much work to do lookup for each subcategory
    let category = this.applianceType.value[0]
  }

  _getApplianceDataForSelectByOfficeLoadFactorType(ctx) {
    // TODO
  }

  getLatentLoad(ctx) {
    if (this.entryType.value == ApplianceLoadsEntryType.ManualEntry) {
      return this.manualGroup.getField('peakLatentPowerOutput').value
    } else if (this.entryType.value == ApplianceLoadsEntryType.SelectAppliance) {
      ctx.log(`Appliance type: ${this.applianceType.value}`)
      if (this.applianceType.value[0] == appliance.ApplianceCategory.DishwashingEquipment) {
        let applianceName = this.applianceType.getLastLabel();
        ctx.log(`Fetching data for DishwashingEquipment: ${applianceName}`)
        return ctx.tablesCache.getTable('DishwasherAppliances').getLatentLoad(applianceName) 
      } else if (this.applianceType.value[0] == appliance.ApplianceCategory.CookingAppliances &&
        this.applianceType.value[1] == appliance.CookingApplianceTypes.Unhooded) {
        let applianceName = this.applianceType.getLastLabel();
        ctx.log(`Fetching data for CookingAppliances: ${applianceName}`)
        return ctx.tablesCache.getTable('KitchenAppliances_Unhooded').getLatentLoad(applianceName)
      } 
      return 0;
    } else {
      // No other appliances have latent loads
      return 0;
    }
  }
}
setupClass(ApplianceEntry)

class ApplianceLoads {
  init() {
    this.appliances = []

    this.serFields = [
      ser.arrayField('appliances', () => { return ApplianceEntry.create(); }),
    ]
    this.childObjs = '$auto'
  }

  addAppliance() {
    this.appliances.push(ApplianceEntry.create())
  }
}
setupClass(ApplianceLoads)

class MiscLoads {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;

    this.sensibleLoads = new FieldWithVariableUnits({
      name: 'Sensible Loads',
      type: FieldType.Load,
      units: Units.Load,
      unitOptions: [Units.Load, Units.LoadPerArea, Units.WattsPerFt2],
    })
    this.sensibleLoadsRadiantFraction = new Field({
      name: 'Radiant Fraction',
      type: FieldType.Ratio,
    })
    this.sensibleLoadsFields = [
      'sensibleLoads',
      'sensibleLoadsRadiantFraction',
    ]

    this.latentLoads = new FieldWithVariableUnits({
      name: 'Latent Loads',
      type: FieldType.Load,
      unitOptions: [Units.Load, Units.LoadPerArea, Units.WattsPerFt2,
        Units.GPM, Units.PoundsPerMin
      ],
    })
    this.latentLoadsWaterTemp = new Field({
      name: 'Water Temperature',
      type: FieldType.Temperature,
    })
    this.latentLoadsWaterTemp.setVisibility(() => {
      return this.latentLoads.units == Units.GPM || this.latentLoads.units == Units.PoundsPerMin;
    })
    this.latentLoadsFields = [
      'latentLoads',
      'latentLoadsWaterTemp',
    ]

    this.schedule = Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
      errorWhenEmpty: 'You must create a Schedule in the Schedules tab',
    })

    this.useDiversityFactor = makeYesNoField('Use diversity factor in peak calculations')
    this.diversityFactor = new Field({
      name: 'Diversity Factor',
      type: FieldType.Ratio,
    })
    this.diversityFactor.setVisibility(() => {
      return this.useDiversityFactor.value == YesNo.Yes
    })
    this.scheduleFields = [
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]

    this.serFields = [
      'sensibleLoads',
      'sensibleLoadsRadiantFraction',
      'latentLoads',
      'latentLoadsWaterTemp',
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]
    this.childObjs = '$auto'
  }

  getSchedule() {
    return this.schedule.lookupValue();
  }

  getDiversityFactor() {
    return this.useDiversityFactor.value == YesNo.Yes ? this.diversityFactor.value : 1.0;
  }

  getSensibleLoad(ctx) {
    if (this.sensibleLoads.units == Units.Load) {
      return this.sensibleLoads.value
    } else if (this.sensibleLoads.units == Units.LoadPerArea) {
      return this.sensibleLoads.value * this.spaceInternals.space.floorArea.value
    } else if (this.sensibleLoads.units == Units.WattsPerFt2) {
      return this.sensibleLoads.getValueInUnits(Units.LoadPerArea) * this.spaceInternals.space.floorArea.value
    } else {
      throw new Error("Unknown sensible load units: " + this.sensibleLoads.units)
    }
  }

  getSensibleLoadRadiantFraction() {
    return this.sensibleLoadsRadiantFraction.value
  }

  getLatentLoad(ctx) {
    if (this.latentLoads.units == Units.Load) {
      return this.latentLoads.value
    } else if (this.latentLoads.units == Units.LoadPerArea) {
      return this.latentLoads.value * this.spaceInternals.space.floorArea.value
    } else if (this.latentLoads.units == Units.WattsPerFt2) {
      return this.latentLoads.getValueInUnits(Units.LoadPerArea) * this.spaceInternals.space.floorArea.value
    } else if (this.latentLoads.units == Units.GPM) {
      let h_g = 1061 + 0.444 * this.latentLoadsWaterTemp.value;
      return this.latentLoads.value * h_g * 499.2216;
    } else if (this.latentLoads.units == Units.PoundsPerMin) {
      let h_g = 1061 + 0.444 * this.latentLoadsWaterTemp.value;
      return this.latentLoads.value * h_g * 60;
    } else {
      throw new Error("Unknown latent load units: " + this.latentLoads.units)
    }
  }
}
setupClass(MiscLoads)

export class SpaceInternals {
  init(space) {
    this.space = space;

    this.people = PeopleInternals.create(this)
    this.lighting = LightingInternals.create(this)
    this.motors = MotorsInput.create()
    this.appliances = ApplianceLoads.create()
    this.miscLoads = MiscLoads.create(this)

    this.serFields = [
      'people',
      'lighting',
      'motors',
      'appliances',
      'miscLoads',
    ]
    this.childObjs = '$auto'
  }
}
setupClass(SpaceInternals)
