import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { valOr } from './SharedUtils.js'
import * as ser from './SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from './Field.js'
import { Season, Wall, Roof, Partition, HouseFloor,
  AutomaticOrManual, YesNo, makeNoYesField, makeYesNoField } from './Components.js'
import { FieldWithVariableUnits } from './FieldUtils.js'
import { Units } from './Units.js'

import { gApp, DebugOn } from './State.js'

import { SpaceTypes, SpaceTypeCategory, GetSpaceTypesForCategory, DummySpaceData } from './SpaceTypes.js'
import { VentilationEffectivenessData, } from './VentilationEffectivenessData.js'
import { SpaceInternals } from './SpaceInternals.js'
import { DataTable } from './DataTable.js'
import { CalcContext } from './CalcContext.js'
import { CalcPsychrometrics, PsyCalcMethod } from './Psychrometrics.js'

import { SpaceHeatingCalculator } from './SpaceHeatingCalculator.js'
import { SpaceCoolingCalculator } from './SpaceCoolingCalculator.js'

import { DataCache } from './MaterialData/DataCache.js'
import * as MaterialDataTableRegistry from './MaterialData/MaterialDataTableRegistry.js'

import * as math from 'mathjs'

export let InfiltrationHours = makeEnum({
  AllHours: 'All hours',
  OccupiedHours: 'Only during occupied hours',
})

export let SpaceType = makeEnum({
  Interior: 'Interior',
  Exterior: 'Exterior',
})

export let BuildingMass = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  Heavy: 'Heavy',
})

export let SpacePercentGlass = makeEnum({
  p10: '10%',
  p50: '50%',
  p90: '90%',
})

export let SpaceHasCarpet = makeEnum({
  Yes: 'Yes',
  No: 'No',
})

export class SpaceVentilation {
  init(space) {
    this.space = space

    // Breathing zone ventilation:
    this.ventilationType = Field.makeSelect('Ventilation', AutomaticOrManual)
    this.ventilationType.makeChoicesUpdater(() => {
      if (this.space.spaceCategory.value == 'Unknown') {
        return [AutomaticOrManual.Manual];
      } else {
        return [AutomaticOrManual.Automatic, AutomaticOrManual.Manual];
      }
    });
 
    // Automatic fields:
    this.automaticVentilation = new Field({
      name: 'Estimated Ventilation',
      isOutput: true,
      units: Units.AirFlow,
    })
    this.automaticVentilation.makeUpdater((field) => {
      field.value = this.calcVentilation();
    });
    this.automaticVentilation.setVisibility(() => {
      return this.ventilationType.value == AutomaticOrManual.Automatic;
    })

    // Manual fields:
    this.manualVentilation = new FieldWithVariableUnits({
      name: 'Manual Ventilation',
      type: FieldType.AirFlow,
      units: Units.AirFlow,
      unitOptions: [Units.AirFlow, Units.AirFlowPerPerson, Units.AirFlowPerArea, Units.ACH],
    });
    this.manualVentilation.setVisibility(() => {
      return this.ventilationType.value == AutomaticOrManual.Manual;
    });
    this.minimumVentilationRequirement = new Field({
      name: 'Minimum Ventilation Requirement',
      type: FieldType.AirFlow,
    });
    this.minimumVentilationRequirement.setVisibility(() => {
      return this.ventilationType.value == AutomaticOrManual.Manual;
    })

    // Effectiveness:
    this.ventilationEffectivenessCooling = new Field({
      name: 'Ventilation Effectiveness (cooling) (E_z)',
      type: FieldType.Ratio,
    })
    this.ventilationEffectivenessHeating = new Field({
      name: 'Ventilation Effectiveness (heating) (E_z)',
      type: FieldType.Ratio,
    })

    this.ventilationEffectivenessData = DataTable.create([
        {
          name: 'Ventilation Type',
          field: 'label',
        },
        {
          name: 'Ventilation Effectiveness (E_z)',
          field: 'value',
        }
      ], VentilationEffectivenessData)

    this.serFields = [
      'ventilationType',
      'manualVentilation',

      'ventilationEffectivenessCooling',
      'ventilationEffectivenessHeating',
    ]
    this.childObjs = '$auto'
  }

  getDataForSpace() {
    if (this.space.spaceCategory.value == 'Unknown') {
      return DummySpaceData;
    }
    let spaceCat = SpaceTypeCategory._labels[this.space.spaceCategory.value];
    // TODO - rework this. Should use multi-tiered select
    let data = null;
    try {
      data = lookupData(SpaceTypes, [spaceCat, this.space.spaceType.value]);
    } catch (ex) {
      return DummySpaceData
    }
    return data;
  }

  calcVentilation() {
    if (this.ventilationType.value == AutomaticOrManual.Automatic) {
      let data = this.getDataForSpace();
      console.log("Calculating automatic ventilation. Data: ", data);
      let numOccupants = this.space.internals.people.getNumOccupants();
      let spaceArea = this.space.floorArea.value;
      console.log("Occupants: ", numOccupants);
      console.log("Area: ", spaceArea);
      let ventilationByPerson = data['cfm/person'] * numOccupants;
      let ventilationByArea = data['cfm/ft^2'] * spaceArea;
      return ventilationByPerson + ventilationByArea;
    } else {
      return this.manualVentilation.value;
    }
  }
};
setupClass(SpaceVentilation)

export class SpaceLoadResults {
  init(calcContext) {
    this.calcContext = calcContext;
    this.results = {}
    this.error = null
  }
}
setupClass(SpaceLoadResults)

export class Space {
  init(name, makeId) {
    this.name = Field.makeName(`Space Name`, name)
    this.id = makeId ? gApp.proj().makeId('Space') : 0;

    this.floorArea = new Field({
      name: 'Floor Area',
      type: FieldType.Area,
    })
    this.avgCeilingHeight = new Field({
      name: 'Average Ceiling Height',
      type: FieldType.Length,
    })
    this.spaceCategory = Field.makeSelect('Space Category', SpaceTypeCategory)
    this.spaceType = new Field({
      name: 'Space Type',
      type: FieldType.Select,
      choices: [],
    })
    this.spaceType.makeChoicesUpdater(() => {
      if (this.spaceCategory.value == 'Unknown') {
        return [];
      }
      return GetSpaceTypesForCategory(this.spaceCategory.value);
    })

    this.buildingMass = Field.makeSelect('Construction Weight', BuildingMass)
    this.spaceInteriorExterior = Field.makeSelect('Interior or Exterior', SpaceType)
    this.percentGlass = Field.makeSelect('Percent Glass', SpacePercentGlass)
    this.hasCarpet = Field.makeSelect('Has Carpet', SpaceHasCarpet)

    this.generalFields = [
      'floorArea',
      'avgCeilingHeight',
      'spaceCategory',
      'spaceType',
      'buildingMass',
      'spaceInteriorExterior',
      'percentGlass',
      'hasCarpet',
    ]

    this.ventilation = SpaceVentilation.create(this)

    let infiltrationUnitOptions = [
      Units.AirFlow,
      Units.CfmPerFt2WallArea,
      Units.CfmPerFtCrackLength,
      Units.ACH,
    ]
    this.summerInfiltration = new FieldWithVariableUnits({
      name: 'Summer Infiltration',
      type: FieldType.AirFlow,
      unitOptions: infiltrationUnitOptions,
    });
    this.winterInfiltration = new FieldWithVariableUnits({
      name: 'Winter Infiltration',
      type: FieldType.AirFlow,
      unitOptions: infiltrationUnitOptions,
    });
    this.infiltrationHours = Field.makeSelect('Infiltration Hours', InfiltrationHours)

    this.walls = []
    this.roofs = []
    this.floors = []
    this.partitions = []

    this.internals = SpaceInternals.create(this)

    this.runningCalculations = false;

    // Stored here for debugging
    this.loadResults = null

    this.debugCalcOptions = {
      Calc_latent_loads: true,
      Calc_wall_loads: true,
    }

    this.serFields = [
      'name',
      'id',
      'floorArea',
      'avgCeilingHeight',
      'spaceCategory',
      'spaceType',
      'buildingMass',
      'spaceInteriorExterior',
      'percentGlass',
      'hasCarpet',
      'summerInfiltration',
      'winterInfiltration',
      'infiltrationHours',
      ser.arrayField('walls', () => { return Wall.create(); }),
      ser.arrayField('roofs', () => { return Roof.create(); }),
      ser.arrayField('floors', () => { return HouseFloor.create(); }),
      ser.arrayField('partitions', () => { return Partition.create(); }),
      'internals',
    ]
    this.childObjs = '$auto'
  }

  getSpaceTypeName() {
    let catName = SpaceTypeCategory._labels[this.spaceCategory.value];
    return `${catName}/${this.spaceType.value}`;
  }

  calculateOutputs() {
    // TODO
    let ctx = CalcContext.create();

    let heatingCalculator = SpaceHeatingCalculator.create(this, ctx);
    heatingCalculator.calcOutputs();

    let coolingCalculator = SpaceCoolingCalculator.create(this, ctx);
    coolingCalculator.calcOutputs();
  }

  async calculateLoadsAsync(opts) {
    opts = valOr(opts, {});  

    if (this.runningCalculations) {
      console.log("Already running calculations. Ignoring.");
      return;
    }
    let ctx = CalcContext.create();
    ctx.debugOptions = this.debugCalcOptions;
    let tablesCache = new DataCache();
    MaterialDataTableRegistry.registerCommercialTables(tablesCache);
    ctx.tablesCache = tablesCache;

    this.loadResults = SpaceLoadResults.create(ctx);
    try {
      this.runningCalculations = true;

      ctx.startSection("General")
      ctx.log("Using placeholder values...")

      let proj = gApp.proj();
      let locationData = proj.buildingAndEnv.getLocationData();
      console.log("Location data: ", locationData);
      ctx.toplevelData = {
        locationData: locationData.getOutputs(),
        dayOfYear: 21,
      }
      ctx.buildingAndEnv = proj.buildingAndEnv.getOutputs();

      // TODO - use the actual values
      ctx.t_i_winter = 70;
      ctx.t_o_winter = -20;
      ctx.t_i_summer = 75;
      ctx.t_o_summer = 95;

      // Get from the Zone
      ctx.t_i_summer_occupied = 75;
      ctx.t_i_summer_unoccupied = 75;

      ctx.altitude  = 50;
      ctx.summerIndoorRH = 0.50;
      ctx.winterIndoorRH = 0.30;
      ctx.endSection()

      ctx.t_i = ctx.t_i_winter;
      ctx.t_o = ctx.t_o_winter;
      let heatingCalculator = SpaceHeatingCalculator.create(this, ctx);
      let heatingResults = heatingCalculator.calcOutputs();

      ctx.t_i = ctx.t_i_summer;
      ctx.t_o = ctx.t_o_summer;
      let coolingCalculator = SpaceCoolingCalculator.create(this, ctx);
      let coolingResults = await coolingCalculator.calcOutputs();

      this.loadResults.results = {
        heating: heatingResults,
        cooling: coolingResults,
      }
    } catch (err) {
      ctx.logFatalError(err);
      this.loadResults.error = err;
    } finally {
      this.runningCalculations = false;
    }

    return this.loadResults;
  }

  async _calculateDebugLoadsAsync() {
    return await this.calculateLoadsAsync({debugMode: true});
  }

  calculateDebugLoads() {
    this._calculateDebugLoadsAsync();
  }

  getSummerIndoorTemp(ctx, hourIndex) {
    // If occupied hr, use Zone occupied temp, otherwise use
    // zone unoccupied temp
    let occupancySched = this.internals.people.getSchedule().getData();
    return occupancySched[hourIndex] > 0 ? ctx.t_i_summer_occupied : ctx.t_i_summer_unoccupied;
  }

  getSummerOutdoorTemp(ctx, monthIndex, hourIndex) {
    // TODO - use actual data. Impl Eq L5 from the doc
    return ctx.t_o_summer;
  }

  getNumOccupants() {
    return this.internals.people.getNumOccupants();
  }

  getOccupancySchedule() {
    return this.internals.people.getSchedule();
  }

  getNumOccupantsAtHour(hourIndex) {
    let schedData = this.getOccupancySchedule().getData();
    return schedData[hourIndex]*this.getNumOccupants();
  }

  get_Q_supply_cool(ctx) {
    // TODO
    return math.matrix(12, 24)
  }

  get_Q_supply_heat(ctx) {
    // TODO
    return -1
  }

  getExposedArea(ctx) {
    let A_exp_walls = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      A_exp_walls += wall.getArea();
    }
    let A_exp_roofs = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      A_exp_roofs += roof.getArea();
    }
    let A_exp_floors = 0;
    for (let i = 0; i < this.floors.length; i++) {
      let floor = this.floors[i];
      if (floor.isFloorRaised()) {
        A_exp_floors += floor.getArea();
      }
    }
    return ctx.eval('A_exp_walls + A_exp_roofs + A_exp_floors', {
      A_exp_walls: A_exp_walls,
      A_exp_roofs: A_exp_roofs,
      A_exp_floors: A_exp_floors,
    }, 'A_exp');
  }

  getTotalCrackLength(ctx) {
    let L_crack_windows = 0;
    let L_crack_doors = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      for (let j = 0; j < wall.windows.length; j++) {
        let window = wall.windows[j];
        let windowType = window.getWindowType();
        L_crack_windows += window.quantity.value * windowType.getPerimeter();
      }
      for (let j = 0; j < wall.doors.length; j++) {
        let door = wall.doors[j];
        let doorType = door.getDoorType();
        L_crack_doors += door.quantity.value * doorType.getPerimeter();
      }
    }
    let L_crack_skylights = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      for (let j = 0; j < roof.skylights.length; j++) {
        let skylight = roof.skylights[j];
        let skylightType = skylight.getSkylightType();
        L_crack_skylights += skylight.quantity.value * skylightType.getPerimeter();
      }
    }
    return ctx.eval('L_crack_windows + L_crack_doors + L_crack_skylights', {
      L_crack_windows: L_crack_windows,
      L_crack_doors: L_crack_doors,
      L_crack_skylights: L_crack_skylights,
    }, 'L_crack');
  }

  getTotalFloorArea(ctx) {
    let A_floor = 0;
    for (let i = 0; i < this.floors.length; i++) {
      let floor = this.floors[i];
      A_floor += floor.getArea();
    }
    return A_floor;
  }

  calcInfiltrationFlowRate(ctx, season) {
    let infiltrationField = season == Season.Summer ? this.summerInfiltration : this.winterInfiltration;
    ctx.Inp = infiltrationField.value;
    ctx.InpUnits = infiltrationField.units;

    switch (ctx.InpUnits) {
      case Units.AirFlow: {
        ctx.Q_inf = ctx.eval('Inp', {}, 'Q_inf');
        break;
      }
      case Units.CfmPerFt2WallArea: {
        ctx.A_exp = this.getExposedArea(ctx);
        ctx.Q_inf = ctx.eval('Inp*A_exp', {}, 'Q_inf');
        break;
      }
      case Units.CfmPerFtCrackLength: {
        ctx.L_crack = this.getTotalCrackLength(ctx);
        ctx.Q_inf = ctx.eval('Inp*L_crack', {}, 'Q_inf');
        break;
      }
      case Units.ACH: {
        ctx.A_floor = this.getTotalFloorArea(ctx);
        ctx.Q_inf = ctx.eval('Inp*A_floor*H_c/60', {
          H_c: this.avgCeilingHeight.value,
        }, 'Q_inf');
        break;
      }
      default:
        throw new Error(`Unknown infiltration unit: ${this.winterInfiltration.units}`);
    }
    return ctx.Q_inf;
  }
}
setupClass(Space)
