import { ref, reactive, watch, watchEffect } from 'vue'

export let DoorsData = {
  SlabDoor: {
    WoodSlabInWoodFrame: 0.46,
    InsulatedSteelSlabWithWoodEdgeInWoodFrame: 0.16,
    InsulatedSteelSlabWithMetalEdgeInMetalFrame: 0.37,
    CardboardHoneycombSlab: 0.61,
  },
  StileAndRail: {
    AluminumWithoutThermalBreak: 1.32,
    AluminumWithThermalBreak: 1.13,
  },
  RevolvingDoor: {
    ThreeWing: {
      n8by7: 0.79,
      n10by8: 0.8,
    },
    FourWing: {
      n7by6p5: 0.63,
      n7by7p5: 0.64,
    }
  },
  SteelEmergencyExitDoor: {
    WithThermalBreak: {
      One3f8: {
        HoneycombKraftPaper: {Single: 0.57, Double: 0.52},
        MineralWoolWithSteelRibs: {Single: 0.44, Double: 0.36},
        PolyurethaneFoam: {Single: 0.34, Double: 0.28},
      },
      One3f4: {
        HoneycombKraftPaper: {Single: 0.57, Double: 0.54},
        MineralWoolWithSteelRibs: {Single: 0.41, Double: 0.33},
        PolyurethaneFoam: {Single: 0.31, Double: 0.26},
      }
    },
    WithoutThermalBreak: {
      One3f8: {
        HoneycombKraftPaper: {Single: 0.6, Double: 0.55},
        MineralWoolWithSteelRibs: {Single: 0.47, Double: 0.39},
        PolyurethaneFoam: {Single: 0.37, Double: 0.31},
      },
      One3f4: {
        HoneycombKraftPaper: {Single: 0.6, Double: 0.57},
        MineralWoolWithSteelRibs: {Single: 0.44, Double: 0.37},
        PolyurethaneFoam: {Single: 0.34, Double: 0.3},
      }
    }
  },
  SteelSectional: {
    One_3f4: {
      PolyurethaneThermallyBroken: 0.28,
    },
    One_3f8: {
      ExtrudedPolystreneWithSteelRibs: 0.39,
      ExpandedPolystreneWithSteelRibs: 0.36,
    },
    Two: {
      ExtrudedPolystreneWithSteelRibs: 0.33,
      ExpandedPolystreneWithSteelRibs: 0.31,
    },
    Three: {
      ExtrudedPolystreneWithSteelRibs: 0.28,
      ExpandedPolystreneWithSteelRibs: 0.27,
    },
    Four: {
      ExtrudedPolystreneWithSteelRibs: 0.25,
      ExpandedPolystreneWithSteelRibs: 0.24,
    },
    Six: {
      ExtrudedPolystreneWithSteelRibs: 0.21,
      ExpandedPolystreneWithSteelRibs: 0.21,
    }
  },
  AircraftHangarDoor: {
    Four: {
      ExpandedPolystrene: {n72by12: 0.25, n240by50: 0.16},
      MineralWoolWithSteelRibs: {n72by12: 0.25, n240by50: 0.16},
      ExtrudedPolystrene: {n72by12: 0.23, n240by50: 0.15},
      NoInsulation: {n72by12: 1.1, n240by50: 1.23},
    },
    Six: {
      ExpandedPolystrene: {n72by12: 0.21, n240by50: 0.13},
      MineralWoolWithSteelRibs: {n72by12: 0.23, n240by50: 0.13},
      ExtrudedPolystrene: {n72by12: 0.2, n240by50: 0.12},
      NoInsulation: {n72by12: 1.1, n240by50: 1.23},
    },
  }
};

