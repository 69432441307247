import { 
  setupClass, 
} from './Base.js'

import {
  SystemType,
  ZoneAirflowCalcMethod,
} from './System.js'

import { Season, YesNo, RecoveryType, } from './Components.js'

import { CalcPsychrometrics, PsyCalcMethod } from './Psychrometrics.js'

import { DailyFractionalDBRange } from './WeatherData/DailyFractionalDBRange.js'

import * as math from 'mathjs'
import { MatrixUtils, makeVector, makeMonthVector, makeHourVector, } from './Math.js'

import * as psy from './Psychrometrics.js'

function maxInArray(arr) {
  let max = arr[0]
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]
    }
  }
  return max
}


class ZoneResult {
  constructor() {
    this.spaceResults = []
  }
}

export class SystemLoadCalculator {
  init(system, ctx) {
    this.system = system
    this.ctx = ctx

    this.zoneResults = []
  }

  calc_T_db_out(hr, mo) {
    let ctx = this.ctx
    let locData = ctx.toplevelData.locationData;
    return ctx.eval("T_db_max_mo - DBR_mo*F_dbr_hr", {
      T_db_max_mo: locData.dryBulbDesignTempsByMonth[ctx.designTemp][mo],
      DBR_mo: locData.dryBulbRangeByMonth[mo],
      F_dbr_hr: DailyFractionalDBRange[hr],
    }, 'T_db_out')
  }

  calc_T_wb_out(hr, mo) {
    let ctx = this.ctx
    return ctx.eval("T_wb_max_mo - WBR_mo*F_dbr_hr", {
      T_db_max_mo: locData.wetBulbDesignTempsByMonth[ctx.designTemp][mo],
      WBR_mo: locData.wetBulbRangeByMonth[mo],
      F_dbr_hr: DailyFractionalDBRange[hr],
    }, 'T_wb_out')
  }

  /*
  The outdoor air percentage in supply air.
  System must be a single-zone CAV.
  */
  calc_Percent_OA(cavInputs) {
    let ctx = this.ctx
    ctx.startSection("Percent OA")

    let spaces = this.system.zones[0].spaces
    if (cavInputs.zoneAirflowCalcMethod.value == ZoneAirflowCalcMethod.SumOfPeaks) {
      ctx.M_OA = makeVector(spaces.length)
      for (let i = 0; i < spaces.length; i++) {
        let space = spaces[i]
        // TODO - set these
        ctx.M_OA[i] = ctx.eval('V_oz_space / Q_supply_space', {
          V_oz_space: -1,
          Q_supply_space: -1,
        }, 'M_OA')
      }
      ctx.Percent_OA = ctx.eval('maxInArray(M_OA)', {
        maxInArray,
      }, 'Percent_OA')
    } else if (cavInputs.zoneAirflowCalcMethod.value == ZoneAirflowCalcMethod.TotalPeak) {
      ctx.M_OA_star = makeVector(spaces.length)
      for (let i = 0; i < spaces.length; i++) {
        let space = spaces[i]
        // TODO - set these
        ctx.M_OA_star[i] = ctx.eval('V_oz_space / Q_supply_space_star', {
          V_oz_space: -1,
          Q_supply_space_star: -1,
        }, 'M_OA_star')
      }
      ctx.Percent_OA = ctx.eval('maxInArray(M_OA_star)', {
        maxInArray,
      }, 'Percent_OA')
    } else {
      throw new Error("Unsupported zoneAirflowCalcMethod: " + cavInputs.zoneAirflowCalcMethod.value)
    }
    ctx.endSection()
    return ctx.Percent_OA
  }

  calc_V_ot_single_zone_CAV() {
    let ctx = this.ctx
    ctx.startSection("V_ot single zone CAV")
    let cavInputs = this.system.cavSystemInputs
    // TODO - calc Q_supply
    ctx.Percent_OA = this.calc_Percent_OA(cavInputs)
    ctx.V_ot = ctx.eval("Percent_OA * Q_supply", {
      Q_supply: 0,
    }, 'V_ot')
    ctx.endSection()
    return ctx.V_ot
  }

  calc_V_ot_multi_zone_CAV() {
    let ctx = this.ctx
    ctx.startSection("V_ot multi zone CAV")
    // TODO - implement this
    ctx.V_ot = -1
    ctx.endSection()
    return ctx.V_ot
  }

  async calc_V_ot() {
    let ctx = this.ctx
    ctx.startSection("Outdoor air intake")
    if (this.system.getSystemType() == SystemType.CAV) {
      if (this.system.zones.length == 1) {
        ctx.V_ot = this.calc_V_ot_single_zone_CAV()
      } else if (this.system.zones.length > 1) {
        ctx.V_ot = this.calc_V_ot_multi_zone_CAV()
      } else {
        throw new Error("The system has no zones")
      }
    } else if (this.system.getSystemType() == SystemType.VAV) {
      // VAV is treated the same as multi-zone CAV
      ctx.V_ot = this.calc_V_ot_multi_zone_CAV()
    } else {
      throw new Error("Unsupported system type: " + this.system.getSystemType())
    }
    let res = ctx.V_ot
    ctx.endSection()
    return res
  }

  async calcCooling() {
  }

  async calcHeating() {
  }

  getSpaceResults(zoneIndex, spaceIndex) {
    return this.zoneResults[zoneIndex].spaceResults[spaceIndex]
  }

  // Minimum primary airflow rate
  calc_V_pz_min_space(zoneIndex, spaceIndex) {
    // TODO - what is V_oz_space?
    let res = ctx.eval('1.5*V_oz_space', {
    }, 'V_pz_min_space')
    return res
  }

  calc_Q_supply_cool_space(zoneIndex, spaceIndex) {
    let spaceResults = this.getSpaceResults(zoneIndex, spaceIndex)
    // TODO - must index?
    let q_cool_space = spaceResults.cooling.q_sensible;
    // TODO - fill in vars
    // TODO - make matrix
    let T_supply_cool = this.system.getCoolingSupplyTemp()
    let v_supply_cool = ctx.call(psy.CalcPsychrometrics, T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.90
      }).v
    let V_pz_min_space = this.calc_V_pz_min_space(zoneIndex, spaceIndex)
    let res = ctx.eval('max(q_cool_space*v_supply_cool/(60*0.25*(T_cool_zone - T_supply_cool)), ' +
      'V_pz_min_space)', {
        q_cool_space,
        T_supply_cool,
        v_supply_cool,
        V_pz_min_space,
      }, 'Q_supply_cool_space')
    return res
  }

  calc_Q_supply_heat_space(zoneIndex, spaceIndex) {
    let spaceResults = this.getSpaceResults(zoneIndex, spaceIndex)
    let q_heat_space = spaceResults.heating.q_sensible;
    // TODO - fill in vars
    let T_supply_heat = this.system.getHeatingSupplyTemp()
    let zone = this.system.zones[zoneIndex]
    let RH = 0.20;
    let v_supply_heat = ctx.call(psy.CalcPsychrometrics, T_supply_heat, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH,
      }).v
    let V_pz_min_space = this.calc_V_pz_min_space(zoneIndex, spaceIndex)
    let res = ctx.eval('max(q_heat_space*v_supply_heat/(60*0.25*(T_supply_heat - T_heat_space)), ' +
      'V_pz_min_space)', {
        q_heat_space,
        T_supply_heat,
        v_supply_heat,
        V_pz_min_space,
      }, 'Q_supply_cool_space')
    return res
  }

  get_Q_supply_cool_spaces() {
    // TODO - account for quantity of spaces here?
    let data = []
    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      let zone = this.system.zones[zoneIndex]
      let spaces = zone.spaces
      for (let spaceIndex = 0; spaceIndex < spaces.length; spaceIndex++) {
        let space = spaces[spaceIndex]
        let Q_supply_cool_space = this.calc_Q_supply_cool_space(zoneIndex, spaceIndex)
        data.push(Q_supply_cool_space)
      }
    }
    return data
  }

  get_Q_supply_heat_spaces() {
    // TODO - account for quantity of spaces here?
    let data = []
    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      let zone = this.system.zones[zoneIndex]
      let spaces = zone.spaces
      for (let spaceIndex = 0; spaceIndex < spaces.length; spaceIndex++) {
        let space = spaces[spaceIndex]
        let Q_supply_heat_space = this.calc_Q_supply_heat_space(zoneIndex, spaceIndex)
        data.push(Q_supply_heat_space)
      }
    }
    return data
  }

  calc_Q_supply_CAV() {
    let ctx = this.ctx;
    ctx.startSection("Calc Q_supply CAV")
    let separateAirflows = this.system.separateAirflow.value;
    let zoneAirflowCalcMethod = this.system.cavSystemInputs.zoneAirflowCalcMethod.value;
    if (zoneAirflowCalcMethod == ZoneAirflowCalcMethod.SumOfPeaks) {
      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces().map(spaceVal => {
        return {Q_supply_cooling_space: spaceVal}
      })
      let Q_supply_cooling = ctx.evalSum(Q_supply_cooling_arr,
        'maxInMatrix(Q_supply_cool_space)', 'Q_supply_cooling')

      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces().map(spaceVal => {
        return {Q_supply_heating_space: spaceVal}
      })
      let Q_supply_heating = ctx.evalSum(Q_supply_heating_arr,
        'Q_supply_heating_space', 'Q_supply_heating')

      if (separateAirflows == YesNo.Yes) {
        ctx.Q_supply_cooling = Q_supply_cooling
        ctx.Q_supply_heating = Q_supply_heating
      } else {
        let max_Q_supply = Math.max(Q_supply_cooling, Q_supply_heating)
        ctx.Q_supply_cooling = max_Q_supply
        ctx.Q_supply_heating = max_Q_supply
      }
    } else if (zoneAirflowCalcMethod == ZoneAirflowCalcMethod.TotalPeak) {
      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces()
      let Q_supply_cooling_sum = math.matrix(12, 24)
      for (const Q_supply_cooling_space of Q_supply_cooling_arr) {
        Q_supply_cooling_sum = math.add(Q_supply_cooling_sum, Q_supply_cooling_space)
      }
      let Q_supply_cooling = ctx.eval('maxInMatrix(Q_supply_cooling_sum)', 'Q_supply_cooling')

      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces().map(spaceVal => {
        return {Q_supply_heating_space: spaceVal}
      })
      let Q_supply_heating = ctx.evalSum(Q_supply_heating_arr,
        'Q_supply_heating_space', 'Q_supply_heating')

      if (separateAirflows == YesNo.Yes) {
        ctx.Q_supply_cooling = Q_supply_cooling
        ctx.Q_supply_heating = Q_supply_heating
      } else {
        let max_Q_supply = Math.max(Q_supply_cooling, Q_supply_heating)
        ctx.Q_supply_cooling = max_Q_supply
        ctx.Q_supply_heating = max_Q_supply
      }
    } else {
      throw new Error("Unsupported zoneAirflowCalcMethod: " + zoneAirflowCalcMethod)
    }
    let res = {
      Q_supply_cooling: ctx.Q_supply_cooling,
      Q_supply_heating: ctx.Q_supply_heating,
    }
    ctx.endSection()
    return res
  }

  calc_Q_supply_VAV() {
    let ctx = this.ctx
    ctx.startSection("Calc Q_supply VAV")

      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces().map(spaceVal => {
        return {Q_supply_cooling_space: spaceVal}
      })
      ctx.Q_supply_cooling = ctx.evalSum(Q_supply_cooling_arr,
        'Q_supply_cool_space', 'Q_supply_cooling')

      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces().map(spaceVal => {
        return {Q_supply_heating_space: spaceVal}
      })
      ctx.Q_supply_heating = ctx.evalSum(Q_supply_heating_arr,
        'Q_supply_heating_space', 'Q_supply_heating')

    let res = {
      Q_supply_cooling: ctx.Q_supply_cooling,
      Q_supply_heating: ctx.Q_supply_heating,
    }
    ctx.endSection()
    return res
  }

  calc_Q_supply() {
    let ctx = this.ctx
    ctx.startSection("Calc Q_supply")

    let systemType = this.system.getSystemType()
    if (systemType == SystemType.CAV) {
      ctx.res = this.calc_Q_supply_CAV()
    } else if (systemType == SystemType.VAV) {
      ctx.res = this.calc_Q_supply_VAV()
    } else {
      throw new Error("Unsupported system type: " + systemType)
    }

    let res = ctx.res
    ctx.endSection()
    return res
  }

  isSystemOccupied(hr) {
    // TODO - impl
    return true
  }

  // System occupacy
  calc_O_system(hr) {
    let ctx = this.ctx
    ctx.startSection("Calc O_system")
    ctx.O_system = 0
    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      let zone = this.system.zones[zoneIndex]
      for (let spaceIndex = 0; spaceIndex < this.system.zones[zoneIndex].spaces.length; spaceIndex++) {
        let spaceType = zone.spaces[spaceIndex].getSpaceType()
        ctx.O_space = spaceType.getNumOccupants()
        ctx.S_space = spaceType.getOccupancySchedule().getData()[hr]
        ctx.O_space = ctx.eval('N*O_space*S_space', {
          N: zone.quantity.value,
        }, 'O_space')
        ctx.O_system += ctx.O_space
      }
    }
    let res = ctx.O_system
    ctx.endSection()
    return res
  }

  calc_W_return(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc W_return")

    ctx.W_return = 0
    let m_return_zone_sum = 0
    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      ctx.startSection("Zone " + zoneIndex)
      let zone = this.system.zones[zoneIndex]

      // TODO - 
      ctx.Q_supply_zone = 0
      ctx.systemOccupied = this.isSystemOccupied(hr)
      ctx.T_zone = ctx.systemOccupied ? zone.summerTempOccupied.value : zone.summerTempUnoccupied.value
      ctx.Humidity_setpoint_zone = zone.humiditySetpoint.value / 100.0
      ctx.v_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude, psy.CalcWithHumidityRatio, {
        W: ctx.Humidity_setpoint_zone,
      }, 'v_zone').v

      let W_zone = 0
      let m_return_zone = ctx.eval('Q_supply_zone/v_zone', {
        Q_supply_zone,
        v_zone,
      }, 'm_return_zone')
      ctx.W_return += W_zone * m_return_zone
      m_return_zone_sum += m_return_zone
      ctx.endSection()
    }
    ctx.W_return /= m_return_zone_sum
    let res = ctx.W_return
    ctx.endSection()
    return res
  }

  calc_T_return(mo, hr, Q_supply) {
    let ctx = this.ctx
    ctx.startSection("Calc T_return")
    // TODO - fill in vars
    ctx.T_return = ctx.eval('(1.0/Q_supply)*inner_sum + (q_plenum+q_fan_return)/(60.*0.24*m_return)', {
    }, 'T_return')
    let res = 0
    ctx.endSection()
    return res
  }

  calc_T_mix_cooling(mo, hr, Q_supply) {
    let ctx = this.ctx
    ctx.startSection("Calc T_mix")

    let t_db_out = this.calc_T_db_out(hr, mo)
    let t_wb_out = this.calc_T_wb_out(hr, mo)
    let psychrometrics = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude,
      PsyCalcMethod.CalcWithWetBulbTemp, {
        t_wb_F: t_wb_out
      })
    ctx.h_oa = psychrometrics.h
    ctx.W_oa = psychrometrics.W

    ctx.W_sat_vent = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude, psy.CalcWithRelativeHumidity, {
      RH: 1.0,
    }, 'W_sat_vent').W

    // TODO - calc V_ot
    ctx.T_return = ctx.eval('(1/Q_supply)*', {
    }, 'T_return')

    ctx.m_vent = ctx.eval('V_ot*air_density', {
    }, 'm_vent')
    ctx.m_recirc = ctx.eval('(Q_return-V_ot)/v_return', {
      Q_return: Q_supply,
    }, 'm_recirc')
    ctx.h_return = ctx.eval('0.24*T_return+W_return*(1061+0.444*T_return)', {
    }, 'h_return')
    ctx.W_return = this.calc_W_return(mo, hr)
    ctx.v_return = ctx.call(psy.CalcPsychrometrics, ctx.T_return, ctx.altitude, psy.CalcWithHumidityRatio, {
      W: ctx.W_return,
    }, 'v_return').v

    let heatRecovery = this.system.heatRecovery;
    let recoveryType = heatRecovery.recoveryType.value

    if (recoveryType != RecoveryType.None) {
      ctx.Q_exhaust = heatRecovery.calc_Q_exhaust(ctx, ctx.V_ot)
      ctx.m_exhaust = ctx.eval('Q_exhaust/v_return', {
      }, 'm_exhaust')
      ctx.m_min = ctx.eval('min(m_exhaust, m_vent)', {
      }, 'm_min')
    }

    if (recoveryType == RecoveryType.None) {
      ctx.h_vent = ctx.h_oa
      ctx.W_vent = ctx.W_oa
    } else if (recoveryType == RecoveryType.HRV) {
      let hrvInputs = heatRecovery.hrvGroup
      ctx.W_vent = ctx.eval('min(W_oa, W_sat_vent)', {
      }, 'W_vent')
      // TODO - missing inputs
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/m_vent * (T_oa-T_return))', {
        epsilon_s: hrvInputs.get('summerEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.h_vent = ctx.eval('0.24*T_vent+W_vent*(1061+0.444*T_vent)', {
      }, 'h_vent')
    } else if (recoveryType == RecoveryType.ERV) {
      // TODO - missing inputs
      let ervInputs = heatRecovery.ervGroup
      ctx.h_vent = ctx.eval('h_oa-(epsilon_T*m_min/m_vent*(h_oa-h_return))', {
        epsilon_T: ervInputs.get('summerTotalEfficiency').value / 100.0,
      }, 'h_vent')
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/m_vent*(T_oa-T_return))', {
        epsilon_s: ervInputs.get('summerSensibleEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.W_vent = ctx.eval('min((h_vent-0.24*T_vent)/(1061+0.444*T_vent), W_sat_vent)', {
      }, 'W_vent')
    } else {
      throw new Error("Unsupported recovery type: " + recoveryType)
    }

    let economizerOn = false
    if (economizerOn) {
      // TODO - calc h_oa and W_oa from psychrometrics docs
      ctx.F_max = this.system.getEconomizerMaxOutdoorAirFraction()
      ctx.h_mix = ctx.eval('F_max*h_oa+(1-F_max)/h_oa', {
      }, 'h_mix')
      ctx.W_mix = ctx.eval('F_max*W_oa+(1-F_max)*W_oa', {
      }, 'W_mix')
    } else {
      ctx.h_mix = ctx.eval('(h_vent*m_vent + h_return*m_recirc)/(m_event+m_recirc)', {
      }, 'h_mix')
      ctx.W_mix = ctx.eval('(W_vent*m_vent+W_return*m_recirc)/(m_event+m_recirc)', {
      }, 'W_mix')
    }
    let res = ctx.eval('(h_mix - 1061*W_mix)/(0.24+0.444*W_mix)', {
    }, 'T_mix')
    ctx.endSection()
    return res
  }

  calc_q_cooling_system(Q_supply_cooling) {
    let ctx = this.ctx
    ctx.startSection("q_cooling_system")
    ctx.q_cooling_sens = math.matrix(12, 24)
    ctx.q_cooling_lat = math.matrix(12, 24)
    let T_supply_cool = this.system.getCoolingSupplyTemp()
    for (let mo = 0; mo < 12; mo++) {
      ctx.startSection("Month " + mo)
      for (let hr = 0; hr < 24; hr++) {
        ctx.startSection("Hour " + hr)
        let Q_supply = Q_supply_cooling.get([mo, hr])
        // TODO - retrieve this properly
        let v_supply = 0
        let T_mix = 0
        let q_supply_fan = 0
        let q_sens = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(T_mix-T_supply) + ' +
          'q_fan_supply/(60*0.24*Q_supply*(1.0/v_supply))', {
            Q_supply,
            T_supply: T_supply_cool,
            T_mix,
            v_supply,
            q_supply_fan,
        }, 'q_sens');
        ctx.q_cooling_sens.set([mo, hr], q_sens)

        let W_mix = 0
        let W_supply = 0
        let T_db_avg = (T_mix + T_supply_cool) / 2
        let q_lat = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(W_mix-W_supply)*(1061+0.444*T_db_avg)', {
          Q_supply,
          v_supply,
          W_mix,
          W_supply,
          T_db_avg,
        }, 'q_lat');
        ctx.q_cooling_lat.set([mo, hr], q_lat)

        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('q_cooling_sens', ctx.q_cooling_sens)
    ctx.logLoadMatrix('q_cooling_lat', ctx.q_cooling_lat)

    let res = {
      q_cooling_sens: ctx.q_cooling_sens,
      q_cooling_lat: ctx.q_cooling_lat,
    }

    ctx.endSection()
    return res;
  }

  calc_q_heating_system(Q_supply_heating) {
    let ctx = this.ctx
    ctx.startSection("q_heating_system")
    ctx.q_heating = math.matrix(12, 24)
    let T_supply_heat = this.system.getHeatingSupplyTemp()
    for (let mo = 0; mo < 12; mo++) {
      ctx.startSection("Month " + mo)
      for (let hr = 0; hr < 24; hr++) {
        ctx.startSection("Hour " + hr)
        let Q_supply = Q_supply_heating.get([mo, hr])
        // TODO - retrieve this properly
        let v_supply = 0
        let T_mix_heat = 0
        let q_heat = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(T_supply_heat-T_mix)', {
            Q_supply,
            T_supply_heat,
            T_mix_heat,
            v_supply,
        }, 'q_heat');
        ctx.q_heating.set([mo, hr], q_heat)

        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('q_heating_sens', ctx.q_heating_sens)
    ctx.logLoadMatrix('q_heating_lat', ctx.q_heating_lat)

    let res = {
      q_heating_sens: ctx.q_heating_sens,
      q_heating_lat: ctx.q_heating_lat,
    }

    ctx.endSection()
    return res;
  }

  calc_q_heating_system() {
    let ctx = this.ctx
    ctx.startSection("q_heating_system")
    ctx.endSection()
  }

  async calcSpaceResults() {
    // Calc the results for each space, and store them for later use
    let ctx = this.ctx
    ctx.startSection("Space results")

    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      let zone = this.system.zones[zoneIndex]
      ctx.startSection(`Zone ${zoneIndex}`)
      let zoneResult = new ZoneResult()
      this.zoneResults.push(zoneResult)
      for (let spaceIndex = 0; spaceIndex < zone.spaces.length; spaceIndex++) {
        let space = zone.spaces[spaceIndex]
        let spaceType = space.getSpaceType()
        ctx.startSection("Space " + spaceType.name.value)
        let spaceResults = await spaceType.calculateLoadsAsync()
        ctx.spaceResults = spaceResults.results
        zoneResult.spaceResults.push(ctx.spaceResults)
        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.endSection()
  }

  async calcLoads() {
    let ctx = this.ctx
    ctx.startSection("System loads")

    ctx.startSection("Basic")
    // TODO - set these properly
    ctx.air_density_0 = 0.074
    ctx.altitude  = 50;
    ctx.P_loc = 1
    ctx.P_atm = 1
    ctx.air_density = ctx.eval('air_density_0 * P_loc / P_atm', {}, 'air_density')

    await this.calcSpaceResults()

    ctx.endSection()

    ctx.endSection()
  }
}
setupClass(SystemLoadCalculator)