<script setup>
import { ref, computed, onMounted, watch, } from 'vue'
import { gApp, DebugOn, Wall, WallWindow, WallDoor,
  Roof, RoofSkylight, Partition, FloorType, } from './State.js'
import { removeElem } from './SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import LocationData from './LocationData.vue'
import HouseResults from './HouseResults.vue'
import BasicModal from './BasicModal.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputGroup from './InputGroup.vue'
import HelpButton from './HelpButton.vue'
import HelpSection from './HelpSection.vue'
import BuildingResults from './BuildingResults.vue'

let shareModal = ref(null);
let sharedUserEmail = ref("");

let renameProjModal = ref(null);
let newProjName = ref("");

let deleteProjModal = ref(null);
let confirmDeleteText = ref("");

let proj = computed(() => {
  return gApp.proj();
})

function startShareWithUser() {
  sharedUserEmail.value = "";
  shareModal.value.showModal();
}

function shareWithUser() {
  if (!sharedUserEmail.value) {
    gApp.toast(`Invalid user email. Please give a valid email.`, {type: 'error'})
    return;
  }
  gApp.proj().addSharedUser(sharedUserEmail.value);
  sharedUserEmail.value = "";
}

function removeSharedUser(userEmail) {
  gApp.proj().removeSharedUser(userEmail);
}

async function testSaveOnExit() {
  let res = await fetch("http://127.0.0.1:5001/buildingcalc/us-central1/save");
  let text = await res.text();
  console.log("Text: ", text);
}

function startRenameProj() {
  newProjName.value = "";
  renameProjModal.value.showModal();
}

async function submitRenameProj() {
  await gApp.proj().rename(newProjName.value);
}

function startDeleteProj() {
  confirmDeleteText.value = "";
  deleteProjModal.value.showModal();
}

function tryDeleteProj() {
  deleteProjModal.value.closeModal();
  gApp.deleteCurProj();
}

let isDeleteBtnEnabled = computed(() => {
  return confirmDeleteText.value == gApp.proj().name;
});

function getProjUsers() {
  let proj = gApp.proj();
  return [proj.userEmail, ...proj.sharedUsers]
}

onMounted(() => {
  // gApp.proj().setHelpId('Home')
})

</script>

<template>
  <div class="Container">
    <div class="mb-s TextAlignCenter">
    <!-- <div class="mb-l"> -->
      <div class="FlexHelp JustifyCenter">
        <h2 class="">Commercial Building Designer</h2>
        <HelpButton id="home" :hasSlot="false"></HelpButton>
      </div>
      <p class="big-help-text mlr-auto MainHelp">
      <!-- <p class="big-help-text MainHelp"> -->
        Welcome to the Commercial Building Designer! Read the <b>Quick Help</b> on the right to get started.
      </p>
      <HelpSection id="home">
        <h4 class="mb-s">Welcome to the Commercial Building Designer!</h4>
        <p class="mb-s">Let's get started.</p>
        <p class=""></p>
        <p class="mb-s">This is the <b>Quick Help</b> pane. Click a <i class="bi-question-circle pc"></i> anywhere to open it.</p>
        <p class="mb-s">This page shows the <b>Load Results</b> for your building. The table will be mostly empty until you give more info
          about your building.
        </p>
        <p>Go to the <router-link :to="{path: `/building/${proj.id}/building-and-env`}"><b>Building and Environment</b></router-link> tab now to get started.</p>
      </HelpSection>
    </div>
    <div class="mb-xl">
      <h3 class="mb-xxs">Results</h3>
      <p><i class="bi-exclamation-diamond mr-xxs"></i><i>This section is under construction</i></p>
      <!--<BuildingResults />-->
    </div>
    <div class="mb-xl" v-if="gApp.isAdminUser()">
      <h3>DEBUG</h3>
      <p class="mb-xs"><i>This section is only visible b/c you are an admin user.</i></p>
      <SimpleCollapse title="IAC Debugger">
        <FieldInput v-model="gApp.proj().debugData.iacDebugger.winType" />
        <FieldInput v-model="gApp.proj().debugData.iacDebugger.inShadingType" />
        <FieldInput v-model="gApp.proj().debugData.iacDebugger.outputIAC" />
      </SimpleCollapse>
    </div>
    <div class="ProjectSettings mb-m">
      <h3 class="mb-xxs">Settings</h3>
      <SimpleCollapse title="Basic" class="ProjectInfo MarginBotXL">
        <!-- <h1 class="PageH1">Overview</h1> -->
        <div title="Name" class="GeneralInfo mt-xs">
          <div class="mb-m">
            <p class="mute-header">Name</p>
            <div class="Flex GapS">
              <p class="f-m Bold">{{ gApp.proj().getUiName() }}</p>
              <button @click="startRenameProj" class="LinkBtn">Rename</button>
            </div>
          </div>
          <div class="mb-l">
            <p class="mute-header">Share Project</p>
            <p class="MarginBotXS f-s help-text">These users have access to the project.</p>
            <table class="SimpleTable UsersTable">
              <tr><th>Project Users</th></tr>
              <tr>
                <td>{{gApp.proj().userEmail}} (Owner)</td>
              </tr>
              <tr v-for="user of gApp.proj().sharedUsers">
                <td>
                  <div class="Flex">
                    <p>{{user}}</p>
                    <button @click="removeSharedUser(user)" class="LinkBtn SharingRemoveBtn pl-s">Remove</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <button @click="startShareWithUser" class="LinkBtn ShareBtn">Add User</button>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <p class="mute-header mb-xs">Other</p>
            <button @click="startDeleteProj" class="LinkBtn delete-text">Delete Project</button>
          </div>
        </div>
        <div v-if="gApp.isAdminUser()" class="AdminDiv mt-m">
          <p class="mute-header">Dev Info</p>
          <p>LOCKED BY: {{gApp.proj().lockedBy ? gApp.proj().lockedBy : '' }}</p>
          <p>My email: {{gApp.user.value.email}}</p>
          <p>My Id: {{gApp.user.value.uid}}</p>
          <p class="mb-s">Project Id: {{ gApp.proj().id }}</p>
          <button class="LinkBtn" @click="gApp.proj().debugOn = !gApp.proj().debugOn">Toggle Debug Mode (Currently {{gApp.proj().debugOn ? 'On' : 'Off'}})</button>
          <button class="LinkBtn" @click="gApp.saveAll()">Save Now</button>
          <button class="LinkBtn" @click="testSaveOnExit">Test Save On Exit</button>
          <button class="LinkBtn" @click="testError()">Throw test error</button>
          <button class="LinkBtn" @click="gApp.checkUserNotifs()">Refresh Notifs</button>
        </div>
      </SimpleCollapse>
    </div>
  </div>

  <BasicModal ref="shareModal" title="Share Project" doneText="Share" @onDone="shareWithUser">
    <p class="mute-header">User email</p>
    <input class="MarginBotM" v-model="sharedUserEmail" />
    <p class="">The user will be able to read and edit the project.</p>
  </BasicModal>
  <BasicModal ref="renameProjModal" title="Rename Project" doneText="Done" @onDone="submitRenameProj">
    <p class="mute-header">New name</p>
    <input class="MarginBotM" v-model="newProjName" />
  </BasicModal>
  <BasicModal ref="deleteProjModal" title="Delete Project" :showDone="false">
    <p class="mb-m">Deleting a project cannot be undone. We recommend you export the project to your hard drive before continuing.</p>
    <p class="mute-header">Confirm</p>
    <p>To confirm, please type the project name ("{{gApp.proj().name}}") below:</p>
    <input class="MarginBotM" v-model="confirmDeleteText" />
    <button @click="tryDeleteProj" :disabled="!isDeleteBtnEnabled">Delete</button>
  </BasicModal>
</template>

<style scoped>
.Section {
  /* margin-bottom: var(--space-s); */
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.UsersTable {
  border: 1px solid var(--b-20);
  min-width: 600px;
}

.ShareBtn {
  padding: var(--space-xxs);
  /* margin-left: auto; */
}

.SharingRemoveBtn {
  margin-left: auto;
}

.MainHelp {
  width: 520px;
}

</style>
