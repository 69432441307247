<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { gApp, } from './State.js'
import { useRoute } from "vue-router"

const route = useRoute();

async function fetchData(newId) {
  let canOpen = await gApp.canOpenProj(newId);
  if (canOpen) {
    await gApp.setCurProj(newId);
    updateHelpIdForPath(route.fullPath);
  } else {
    // If do not have access to this project, return to home
    console.log("Project is locked by someone else! Returning home.");
    gApp.router.replace('/');
  }
}

function updateHelpIdForPath(newPath) {
  console.log("New path: ", newPath);
  let helpIds = {
    '': 'home',
    'build': 'build',
    'walls': 'WallsView',
    'roofs': 'WallsView',
    'windows': 'WindowsView',
    'doors': 'DoorsView',
    'skylights': 'SkylightsView',
    'internalshadings': 'InteriorShadingsView',
    'externalshadings': 'ExteriorShadingsView',
    'bufferspaces': 'BufferSpacesView',
  }
  let pathParts = route.fullPath.split('/');
  let pageId = pathParts.length >= 4 ? pathParts[3] : '';
  if (gApp.proj()) {
    if (pageId in helpIds) {
      gApp.proj().setHelpId(helpIds[pageId]);
      // console.log("Set help id to " + helpIds[pageId]);
    } else {
      gApp.proj().setHelpId(null)
      gApp.proj().setHelpPaneVisible(false);
    }
  }
}

watch(() => {
    return route.params.id;
  },
  (newId) => {
    fetchData(newId);
  }, {immediate: true});

watch(() => {
  return route.fullPath;
},
(newPath) => {
  updateHelpIdForPath(newPath);
}, {immediate: true})

onMounted(() => {
  console.log("Mounting ProjectView");
})

onUnmounted(() => {
  console.log("Unmounting ProjectView.");
  // Save + release the project (no longer editing)
  if (gApp.proj()) {
    gApp.proj().save();
  }
})

</script>

<template>
  <div class="ProjectView">
    <div class="Sidebar PaddingL">
      <template v-if="gApp.proj() !== null">
        <router-link to="/" class="MenuLink Block MarginBotM BackToHomeBtn"><i class="bi-arrow-left-circle MRXS"></i>Projects</router-link>

        <p class="mute-text small-upper pl-m SidebarHeader">House</p>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}`}" class="MenuLink Block"><i class="bi-file-earmark-fill MRXS"></i>Overview</router-link>
        <template v-if="gApp.proj().isCommercial()">
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/building-and-env`}" class="MenuLink Block"><i class="bi-geo-alt MRXS"></i>Building and Environment</router-link>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/systems`}" class="MenuLink Block"><i class="bi-grid MRXS"></i>Systems</router-link>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/spaces`}" class="MenuLink Block MarginBotM"><i class="bi-box MRXS"></i>Spaces</router-link>
        </template>
        <template v-else>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/build`}" class="MenuLink Block MarginBotM"><i class="bi-tools MRXS"></i>Build House</router-link>
        </template>

        <p class="mute-text small-upper pl-m SidebarHeader">Components</p>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/walls`}" class="MenuLink Block"><i class="bi-bricks MRXS"></i>Walls</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/roofs`}" class="MenuLink Block"><i class="bi-house-fill MRXS"></i>Roofs</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/windows`}" class="MenuLink Block"><i class="bi-grid-fill MRXS"></i>Windows</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/doors`}" class="MenuLink Block"><i class="bi-door-closed-fill MRXS"></i>Doors</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/skylights`}" class="MenuLink Block"><i class="bi-brightness-high-fill MRXS"></i>Skylights</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/internalshadings`}" class="MenuLink Block"><i class="bi-shadows MRXS"></i>Internal Shadings</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/externalshadings`}" class="MenuLink Block"><i class="bi-shadows MRXS"></i>External Shadings</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/bufferspaces`}" class="MenuLink Block"><i class="bi-distribute-horizontal MRXS"></i>Buffer Spaces</router-link>
        <template v-if="gApp.proj().isCommercial()">
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/schedules`}" class="MenuLink Block"><i class="bi-alarm MRXS"></i>Schedules</router-link>
        </template>
      </template>
    </div>
    <div class="RightSidebar" v-show="gApp.proj() && gApp.proj().helpPaneVisible">
      <div class="HelpHeader">
        <!-- <button class="LinkBtn float-r" @click="gApp.proj().setHelpPaneVisible(false)">Hide</button> -->
        <button class="float-r LinkBtn CloseX" @click="gApp.proj().setHelpPaneVisible(false)">&#x2715;</button>
        <h3 class=""><i class="mr-xxs bi-lightning-charge"></i>Quick Help</h3>
      </div>
      <!-- Teleport target -->
      <div id="HelpContent">
      </div>
    </div>
    <div class="Main PaddingM">
      <button class="QuickHelpOpener CreateBtn" @click="gApp.proj().setHelpPaneVisible(true)"><i class="bi-lightning-charge mr-xxs"></i>Quick Help</button>
      <div v-if="gApp.proj() !== null">
        <!-- <router-view></router-view> -->
        <!-- This keeps the page alive when changing pages -->
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
      <div v-else>
        <h4 class="upper">Loading...</h4>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*
.router-link-active {
  background-color: red;
}
*/

.ProjectView {
  /* display: grid; */
  /* grid-template-columns: var(--sidebar-w) 1fr; */
  /* gap: var(--space-m); */
  height: 100%;
}

.Sidebar {
  /* padding: var(--space-m); */
  /* min-width: 260px; */
  width: var(--sidebar-w);
  border-right: 1px solid grey;
  height: 100%;
  padding: var(--space-xs) 0 var(--space-m) 0;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-color);
  position: fixed;
  float: left;
  /* font-size: var(--f-m); */
  font-size: var(--f-m);
}

.RightSidebar {
  position: fixed;
  right: 0;
  top: var(--header-height);
  width: 400px;
  max-width: 25%;
  height: calc(100vh - var(--header-height));
  background-color: var(--main-bg);
  /* padding: var(--s); */
  border-left: 1px solid grey;
}

.HelpHeader {
  border-bottom: 1px solid grey;
  padding: var(--s);
}

#HelpContent {
  padding: var(--s);
  padding-bottom: var(--xxl);
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.MenuLink {
  /* text-transform: uppercase; */
  font-weight: bold;
  font-size: var(--f-s);
  color: var(--pc-lightest);
  width: 100%;
  padding: var(--xs) var(--s) var(--xs) var(--m);

  transition: all .05s ease-in-out; 
}

.MenuLink:hover {
  background-color: var(--pc-dark);

  transform: scale(1.1); 
}

.MenuLink i {
  /* color: yellow; */
  margin-right: var(--space-xs);
}

.SidebarHeader {
  color: var(--pc-med2);
}

.QuickHelpOpener {
  float: right;
}

.router-link-exact-active {
  background-color: var(--pc);
  /* background-color: var(--menu-link-active); */
  /* color: white; */
  /* color: var(--pc); */

  /* background-image: linear-gradient(to right,rgba(255,255,255,.09) 0%,rgba(0,0,0,.01) 100%); */
}

.Main {
  width: 100%;
  padding-left: calc(var(--sidebar-w) + var(--space-l));
  /* padding-left: var(--space-xl); */
}

.BackToHomeBtn {
  /* color: var(--pc-light); */
}


</style>
